<template>
  <b-table
    :items="mainList.dataset"
    :fields="tableFields"
    :busy="onLoadingList"
    class="table-bordered table-hover"
  >
    <template v-slot:table-busy>
      <vcl-table :speed="5" :animate="true" :columns="10" />
    </template>
    <template v-slot:cell(index)="row">
      {{ row.index + 1 }}
    </template>
    <template v-slot:cell(id)="row">
      <span
        v-if="
          [
            TRANSACTION_TYPE.CashIn,
            TRANSACTION_TYPE.CashOut,
            TRANSACTION_TYPE.BankIn,
            TRANSACTION_TYPE.BankOut
          ].includes(row.item.type)
        "
      >
        <router-link
          :to="{
            path: '/accounting/transactions/cashbook',
            query: { id: row.item.id }
          }"
          target="_blank"
          >{{ row.item.id }}
        </router-link>
      </span>
      <span v-else>
        {{ row.item.id }}
      </span>
    </template>
    <template v-slot:cell(createdAt)="row">
      <span style="display:block;">{{ formatDate(row.item.createdAt) }}</span>
    </template>
    <template v-slot:cell(amount)="row">
      {{ convertPrice(row.item.amount) }}
    </template>
  </b-table>
</template>

<style>
.break-line {
  white-space: break-spaces;
}
</style>

<script>
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from './../../utils/apiUrl';
import moment from 'moment';
import { TRANSACTION_TYPE } from './../../utils/enum';
const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%'
};

export default {
  props: ['billNumber'],
  components: {
    VclTable
  },
  data() {
    return {
      TRANSACTION_TYPE,
      mainList: {
        dataset: [],
        total: 0,
        pages: 1
      },
      onLoadingList: false,
      tableFields: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '5%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '10%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'createdAt',
          label: 'Ngày giao dịch',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '13%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'typeName',
          label: 'Loại',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '16%'
          },
          tdClass: 'text-left'
        },
        {
          key: 'amount',
          label: 'Số tiền',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '12%'
          },
          tdClass: 'text-right'
        },
        {
          key: 'accountantId',
          label: 'Nợ',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '12%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'accountantRefId',
          label: 'Có',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '12%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'note',
          label: 'Diễn giải',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%'
          },
          tdClass: 'text-left break-line'
        }
      ]
    };
  },
  methods: {
    fetchMainData: async function() {
      // Init request header.
      this.onLoadingList = true;
      ApiService.get(
        cmdUrl.TransactionUrl.forBill.replace('{number}', this.billNumber)
      )
        .then(response => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch(() => {
          this.onLoadingList = false;
        });
    },
    formatDate: function(dateVal) {
      const date = moment(dateVal);
      return date.format('DD/MM/yyyy');
    },
    convertPrice: function(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    }
  },
  mounted() {
    this.fetchMainData();
  }
};
</script>
