<template>
  <div>
    <KTCodePreview v-bind:title="billDetailTitle">
      <template v-slot:preview>
        <b-form ref="form" lazy-validation class="row">
          <b-container>
            <b-row class="col-md-12 mb-1 m-0 p-0" v-if="orderId">
              <div class="alert-header" style="width: 100%">
                Hóa đơn được tạo từ đơn hàng:
                <span
                  class="text-primary orderCode"
                  @click="linkToOrder(orderId)"
                  style="cursor: pointer"
                  >{{ orderId }}</span
                >
              </div>
            </b-row>
            <b-row class="col-md-12 mb-4 m-0 p-0">
              <b-col md="9">
                <b-row style="padding-right: 6px">
                  <b-col class="col-md-3 p-0">
                    <b-form-select
                      v-model="selectTypeSearch"
                      :options="listTypeSearch"
                      size="sm"
                      value-field="id"
                      text-field="name"
                      @change="changePlaceholder"
                    ></b-form-select>
                  </b-col>
                  <b-col class="col-md-9 p-0">
                    <vue-autosuggest
                      v-model="searchProduct"
                      :suggestions="filteredOptionsProduct"
                      @selected="onSelectedProduct"
                      :limit="10"
                      @input="onInputChangProduct"
                      :input-props="inputSearchProductProp"
                      :should-render-suggestions="
                        (size, loading) =>
                          size >= 0 && !loading && searchProduct !== ''
                      "
                    >
                      <div
                        v-if="selectTypeSearch === 1"
                        slot-scope="{ suggestion }"
                        style="display: flex; align-items: center"
                      >
                        <div>
                          <span class="text-primary">
                            ({{ formatMoney(suggestion.item.sellingPrice) }}) (
                          </span>
                          <span
                            v-if="suggestion.item.quantityInStock > 0"
                            class="text-success"
                            >Tồn TCB:
                            {{ suggestion.item.quantityInStock }}</span
                          >
                          <span v-else class="text-danger"
                            >Tồn TCB:
                            {{ suggestion.item.quantityInStock }}</span
                          >
                          )
                          {{ suggestion.item.productName }}
                        </div>
                      </div>
                      <div
                        v-else-if="selectTypeSearch === 2"
                        slot-scope="{ suggestion }"
                        style="display: flex; align-items: center"
                      >
                        <div>
                          <span class="text-primary"
                            >({{
                              formatMoney(suggestion.item.sellingPrice)
                            }})</span
                          >
                          {{ suggestion.item.productName }}-
                          <span class="text-warning"
                            >IMEI: {{ suggestion.item.imeiCode }}</span
                          >
                        </div>
                      </div>
                    </vue-autosuggest>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="3" class="pr-0">
                <b-form-group>
                  <vue-autosuggest
                    style="margin-left: 6px"
                    class="border-radius-none"
                    aria-describedby="input-store-live-feedback"
                    :suggestions="filteredOptions"
                    @selected="onSelectedStore"
                    :limit="10"
                    @input="onInputChange"
                    :input-props="{
                      id: 'autosuggest__input',
                      placeholder: 'Tên cửa hàng',
                      style: 'border-radius:0px!important',
                      disabled: true,
                    }"
                    :should-render-suggestions="
                      (size, loading) => size >= 0 && !loading
                    "
                    v-model="$v.searchStore.$model"
                    :state="validateState('searchStore')"
                  ></vue-autosuggest>
                  <b-form-invalid-feedback
                    :state="validateState('searchStore')"
                    id="input-store-live-feedback"
                    >Vui lòng nhập cửa hàng</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
          <b-container class="bv-example-row">
            <b-row>
              <b-col md="9"></b-col>
              <b-col md="3"></b-col>
            </b-row>
            <b-row>
              <b-col md="9">
                <b-row>
                  <b-col>
                    <table
                      class="
                        table table-bordered table-vertical-center table-hover
                      "
                    >
                      <thead>
                        <tr>
                          <th class="headerTable" style="textalign: center">
                            STT
                          </th>
                          <th class="name headerTable">Sản phẩm</th>
                          <th class="value headerTable">Tồn</th>
                          <th class="value headerTable">SL</th>
                          <th class="code headerTable">Giá</th>
                          <th class="code headerTable">Thành tiền</th>
                          <th class="code headerTable">Chiết khấu</th>
                          <th class="code headerTable">VAT</th>
                          <th v-if="true"></th>
                        </tr>
                      </thead>

                      <tbody v-for="item in listBillItem" :key="item.id">
                        <BillItem
                          v-bind:productItem="item"
                          v-bind:editable="true"
                          v-bind:editIMEI="false"
                          v-on:deleteItem="deleteItemInArray"
                          v-on:update="updateItem"
                          v-on:checkValidImei="checkValidImei"
                          v-on:addProductBonus="showModalAddProductBonus"
                          v-on:changeProductBonus="showModalChangeProductBonus"
                          v-on:addProductPromotion="
                            showModalAddProductPromotion
                          "
                          v-on:addNote="showModalAddNote"
                          v-on:linkToListImei="linkToListImei"
                          v-on:viewInventory="viewInventory"
                          v-on:addProductWarranty="showModalAddProductWarranty"
                          v-on:addWarranty="linkToWarranty"
                        />
                      </tbody>
                      <tfoot>
                        <tr>
                          <th style="text-align: right" id="total" colspan="5">
                            Tổng phải trả:
                          </th>
                          <td style="text-align: right; padding: 0px 20px">
                            {{ formatMoney(totalPrice) }}
                          </td>
                          <td style="text-align: right; padding: 0px 20px"></td>
                          <td style="text-align: right; padding: 0px 20px"></td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label for="input-name">Họ tên:</label>
                      <b-form-input
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="customerName"
                        required
                        placeholder="Họ tên khách hàng"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label for="input-name">Nhân viên bán hàng:</label>
                      <vue-autosuggest
                        aria-describedby="input-phone-live-feedback"
                        :suggestions="filteredOptionsEmployee"
                        @selected="onSelectedSale"
                        :limit="10"
                        @input="onInputChangeSale"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhân viên bán hàng',
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                        v-model="saleName"
                      ></vue-autosuggest>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Công ty:</label>
                      <b-form-input
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="cityName"
                        placeholder="Nhập tên công ty"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group class="required-control">
                      <label>Điện thoại: </label>
                      <vue-autosuggest
                        aria-describedby="input-phone-live-feedback"
                        :suggestions="filteredOptionsCustomer"
                        @selected="onSelectedCustomer"
                        :limit="10"
                        @input="onInputChangCustomer"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'SĐT Khách hàng',
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                        v-model="searchCustomer"
                      ></vue-autosuggest>
                      <b-form-invalid-feedback
                        :state="validateCustomer()"
                        id="input-phone-live-feedback"
                      >
                        Vui lòng nhập số điện thoại
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Nhân viên kỹ thuật:</label>
                      <vue-autosuggest
                        :suggestions="filteredOptionsEmployee"
                        @selected="onSelectedTechnical"
                        :limit="10"
                        @input="onInputChangeTechnical"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhân viên kỹ thuật',
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                        v-model="technicalName"
                      ></vue-autosuggest>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Mã số thuế:</label>
                      <b-form-input
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="taxCode"
                        placeholder="Nhập mã số thuế"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Thành phố:</label>
                      <b-form-select
                        class="select-style"
                        v-model="selectCity"
                        :options="listCity"
                        size="sm"
                        value-field="id"
                        text-field="name"
                        v-on:change="getDistrict"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn thành phố</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Nhân viên thu ngân:</label>
                      <vue-autosuggest
                        :suggestions="filteredOptionsEmployee"
                        @selected="onSelectedCash"
                        :limit="10"
                        @input="onInputChangeCash"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhân viên thu ngân',
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                        v-model="cashierName"
                      ></vue-autosuggest>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Số PO- hợp đồng:</label>
                      <b-form-input
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="contractCode"
                        placeholder="Nhập số PO- hợp đồng"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Quận huyện:</label>
                      <b-form-select
                        class="select-style"
                        v-model="selectDistrict"
                        :options="listDistrict"
                        size="sm"
                        value-field="id"
                        text-field="name"
                        v-on:change="getWard"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn quận</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Quản lý cửa hàng:</label>
                      <vue-autosuggest
                        :suggestions="filteredOptionsEmployee"
                        @selected="onSelectedManager"
                        :limit="10"
                        @input="onInputChangeManager"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Quản lý cửa hàng',
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                        v-model="managerName"
                      ></vue-autosuggest>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Ngày ký hợp đồng:</label>
                      <div class="input-group">
                        <date-picker
                          placeholder="Ngày ký hợp đồng"
                          class="form-control-sm input-style"
                          :config="dpConfigs.dateContract"
                          v-model="dateContract"
                          :wrap="true"
                        ></date-picker>
                        <div class="input-group-append">
                          <b-button
                            class="datepickerbutton"
                            variant="secondary"
                            size="sm"
                            title="Toggle"
                          >
                            <i class="far fa-calendar"></i>
                          </b-button>
                        </div>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Phường xã:</label>
                      <b-form-select
                        class="select-style"
                        v-model="selectWard"
                        :options="listWard"
                        size="sm"
                        value-field="id"
                        text-field="name"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn phường</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Trợ lý cửa hàng:</label>
                      <vue-autosuggest
                        aria-describedby="input-phone-live-feedback"
                        :suggestions="filteredOptionsEmployee"
                        @selected="onSelectedAssistant"
                        :limit="10"
                        @input="onInputChangeAssistant"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Trợ lý cửa hàng',
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                        v-model="assistantName"
                      ></vue-autosuggest>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Địa chỉ:</label>
                      <b-form-input
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="address"
                        placeholder="Địa chỉ khách hàng"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Tiếp đón khách hàng:</label>
                      <vue-autosuggest
                        :suggestions="filteredOptionsEmployee"
                        @selected="onSelectedCustomerReception"
                        :limit="10"
                        @input="onInputChangeCustomerReception"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Tiếp đón khách hàng',
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                        v-model="customerReceptionName"
                      ></vue-autosuggest>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Nguồn khách hàng:</label>
                      <b-form-select
                        v-model="selectedCustomerSource"
                        :options="listCustomerSource"
                        size="sm"
                        value-field="id"
                        text-field="name"
                        class="select-style"
                        disabled-field="notEnabled"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn nguồn khách hàng:</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Nhân viên giao hàng:</label>
                      <vue-autosuggest
                        :suggestions="filteredOptionsEmployee"
                        @selected="onSelectedShipper"
                        :limit="10"
                        @input="onInputChangeShipper"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhân viên giao hàng',
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                        v-model="shipperName"
                      ></vue-autosuggest>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label for="input-name">Ghi chú bán hàng:</label>
                      <b-form-textarea
                        size="sm"
                        class="input-style"
                        v-model="noteCustomer"
                        :placeholder="'Thêm ghi chú'"
                        :rows="6"
                        :max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label for="input-name">Ghi chú nội bộ:</label>
                      <b-form-textarea
                        size="sm"
                        class="input-style"
                        v-model="saleNote"
                        :placeholder="'Thêm ghi chú'"
                        :rows="6"
                        :max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Ghi chú bảo hành:</label>
                      <b-form-textarea
                        size="sm"
                        class="input-style"
                        v-model="warrantyNote"
                        :placeholder="'Thêm ghi chú bảo hành'"
                        :rows="6"
                        :max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="3">
                <b-form-group>
                  <label>Chiết khấu:</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="discount"
                    v-mask="mask"
                    placeholder="Chiết khấu"
                  ></b-form-input>
                </b-form-group>

                <b-form-group>
                  <label>Kiểu chiết khấu:</label>
                  <b-form-select
                    class="select-style"
                    v-model="selectDiscountType"
                    :options="listDiscountType"
                    size="sm"
                    value-field="id"
                    text-field="name"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled
                        >Chọn kiểu chiết khấu</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <p class="textPayment">Thanh toán</p>
                <div class="row">
                  <div class="col-md-6">
                    <p class="textTitlePayment">Tổng tiền:</p>
                  </div>
                  <div class="col-md-6">
                    <p class="text-right textPayment">
                      {{ convertPrice(totalMoney) }}
                    </p>
                  </div>
                </div>
                <div class="row" v-if="totalAmountReturn > 0">
                  <div class="col-md-6">
                    <p class="textTitlePayment">Tiền thu lại:</p>
                  </div>
                  <div class="col-md-6">
                    <p class="text-right textPayment">
                      {{ convertPrice(totalAmountReturn) }}
                    </p>
                  </div>
                </div>
                <div class="row" v-if="depositAmount > 0">
                  <div class="col-md-6">
                    <p class="textTitlePayment">
                      Đặt cọc từ đơn hàng (#
                      {{ orderId }} ):
                    </p>
                  </div>
                  <div class="col-md-6">
                    <p class="text-right textPayment">
                      {{ convertPrice(depositAmount) }}
                    </p>
                  </div>
                </div>
                <div class="row" v-if="transferFromOrder > 0">
                  <div class="col-md-6">
                    <p class="textTitlePayment">
                      Chuyển khoản từ đơn hàng (#
                      {{ orderId }} ):
                    </p>
                  </div>
                  <div class="col-md-6">
                    <p class="text-right textPayment">
                      {{ convertPrice(transferFromOrder) }}
                    </p>
                  </div>
                </div>
                <div class="row" v-if="moneyCredit > 0">
                  <div class="col-md-6">
                    <p class="textTitlePayment">
                      Quẹt thẻ từ đơn hàng (#
                      {{ orderId }} ):
                    </p>
                  </div>
                  <div class="col-md-6">
                    <p class="text-right textPayment">
                      {{ convertPrice(moneyCredit) }}
                    </p>
                  </div>
                </div>
                <div class="row" v-if="moneyInstallment > 0">
                  <div class="col-md-6">
                    <p class="textTitlePayment">
                      Trả góp từ đơn hàng (#
                      {{ orderId }} ):
                    </p>
                  </div>
                  <div class="col-md-6">
                    <p class="text-right textPayment">
                      {{ convertPrice(moneyInstallment) }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <p class="textTitlePayment">Khách cần trả:</p>
                  </div>
                  <div class="col-md-6">
                    <p class="text-right textPayment">
                      {{ convertPrice(totalPrice) }}
                    </p>
                  </div>
                </div>
                <b-form-group>
                  <label>Tiền mặt</label>
                  <b-input-group>
                    <b-input-group-append>
                      <span class="input-group-text" @click="bindingMoney(1)">
                        <i class="fas fa-arrow-right"></i>
                      </span>
                    </b-input-group-append>
                    <b-form-input
                      class="input-style text-right"
                      size="sm"
                      type="text"
                      v-model="cashAmount"
                      placeholder="Nhập số tiền mặt"
                      v-mask="mask"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group v-show="checkCashAmount()">
                  <label>Tài khoản tiền mặt:</label>
                  <vue-autosuggest
                    aria-describedby="input-cash-live-feedback"
                    v-model="selectedCashAcc"
                    :suggestions="filteredCashAccOptions"
                    @selected="onCashAccSelected"
                    :limit="10"
                    @input="onInputCashAccChange"
                    :input-props="{
                      id: 'autosuggest__input',
                      placeholder: 'Nhập tài khoản tiền mặt',
                    }"
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      {{ suggestion.item.name }}
                    </div>
                  </vue-autosuggest>
                  <b-form-invalid-feedback
                    :state="validateCash()"
                    id="input-cash-live-feedback"
                    >Vui lòng chọn tài khoản tiền mặt</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group>
                  <label>Tiền chuyển khoản:</label>
                  <b-input-group>
                    <b-input-group-append>
                      <span class="input-group-text" @click="bindingMoney(2)">
                        <i class="fas fa-arrow-right"></i>
                      </span>
                    </b-input-group-append>
                    <b-form-input
                      class="input-style text-right"
                      size="sm"
                      type="text"
                      v-model="transferAmount"
                      placeholder="Nhập số tiền chuyển khoản"
                      v-mask="mask"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group v-show="checkTransferAmount()">
                  <label>Tài khoản chuyển khoản:</label>
                  <vue-autosuggest
                    aria-describedby="input-transfer-live-feedback"
                    v-model="selectedAccountant"
                    :suggestions="filteredAccOptions"
                    @selected="onAccSelected"
                    :limit="10"
                    @input="onInputAccChange"
                    :input-props="{
                      id: 'autosuggest__input',
                      placeholder: 'Nhập tài khoản chuyển khoản',
                    }"
                    :should-render-suggestions="
                      (size, loading) => size >= 0 && !loading
                    "
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      {{ suggestion.item.name }}
                    </div>
                  </vue-autosuggest>
                  <b-form-invalid-feedback
                    :state="validateTransfer()"
                    id="input-transfer-live-feedback"
                    >Vui lòng chọn tài khoản chuyển
                    khoản</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group v-if="checkTransferAmount()">
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="transferReferenceCode"
                    placeholder="Mã tham chiếu"
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label>Tiền quẹt thẻ:</label>
                  <b-input-group>
                    <b-input-group-append>
                      <span class="input-group-text" @click="bindingMoney(3)">
                        <i class="fas fa-arrow-right"></i>
                      </span>
                    </b-input-group-append>
                    <b-form-input
                      class="input-style text-right"
                      size="sm"
                      type="text"
                      v-model="creditAmount"
                      placeholder="Tiền quẹt thẻ"
                      v-mask="mask"
                      @change="onCreditAmountChange"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>

                <b-form-group v-if="checkCreditPrice()">
                  <label>Tài khoản quẹt thẻ:</label>
                  <vue-autosuggest
                    aria-describedby="input-credit-live-feedback"
                    v-model="selectedCreditAcc"
                    :suggestions="filteredCreditAccOptions"
                    @selected="onCreditAccSelected"
                    :limit="10"
                    @input="onInputCreditAccChange"
                    :input-props="{
                      id: 'autosuggest__input',
                      placeholder: 'Nhập tài khoản quẹt thẻ',
                    }"
                    :should-render-suggestions="
                      (size, loading) => size >= 0 && !loading
                    "
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      {{ suggestion.item.name }}
                    </div>
                  </vue-autosuggest>
                  <b-form-invalid-feedback
                    :state="validateCredit()"
                    id="input-credit-live-feedback"
                    >Vui lòng chọn tài khoản quẹt thẻ</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group v-if="checkCreditPrice()">
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="creditCode"
                    placeholder="Mã giao dịch"
                  ></b-form-input>
                </b-form-group>
                <b-form-group v-if="checkCreditPrice()">
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="creditCardCode"
                    placeholder="Nhập 4 số cuối mã thẻ"
                  ></b-form-input>
                </b-form-group>
                <b-form-group v-if="checkCreditPrice()">
                  <label>Hình thức trả phí:</label>
                  <b-form-select
                    class="select-style"
                    v-model="selectedPayCreditFeeType"
                    :options="listPayCreditFeeType"
                    size="sm"
                    value-field="id"
                    text-field="name"
                    @change="onCreditFeePayTypeChange()"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled
                        >Chọn hình thức trả phí quẹt thẻ</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group v-if="checkCreditPrice()">
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="creditCardFee"
                    placeholder="Phí quẹt thẻ"
                    v-mask="mask"
                  ></b-form-input>
                </b-form-group>
                <b-form-group v-if="checkCreditPrice()">
                  <label>Tài khoản thu phí quẹt thẻ:</label>
                  <vue-autosuggest
                    aria-describedby="input-credit-live-feedback"
                    v-model="selectedCreditFeeAcc"
                    :suggestions="filteredCreditFeeAccOptions"
                    @selected="onCreditFeeAccSelected"
                    :limit="10"
                    @input="onInputCreditFeeAccChange"
                    :input-props="{
                      id: 'autosuggest__input',
                      placeholder: 'Nhập tài khoản thu phí',
                    }"
                    :should-render-suggestions="
                      (size, loading) => size >= 0 && !loading
                    "
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      {{ suggestion.item.name }}
                    </div>
                  </vue-autosuggest>
                </b-form-group>
                <b-form-group>
                  <label>Trả góp</label>
                  <b-input-group>
                    <b-input-group-append>
                      <span class="input-group-text" @click="bindingMoney(4)">
                        <i class="fas fa-arrow-right"></i>
                      </span>
                    </b-input-group-append>
                    <b-form-input
                      class="input-style text-right"
                      size="sm"
                      type="text"
                      v-model="installedMoneyAmount"
                      placeholder="Trả góp"
                      v-mask="mask"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group v-if="checkInstallmentAmount()">
                  <label>Dịch vụ trả góp:</label>
                  <vue-autosuggest
                    aria-describedby="input-installment-live-feedback"
                    v-model="selectedInstallment"
                    :suggestions="filteredOptionsInstallment"
                    @selected="onInstallmentSelected"
                    :limit="10"
                    @input="onInputInstallmentChange"
                    :input-props="{
                      id: 'autosuggest__input',
                      placeholder: 'Nhập dịch vụ trả góp',
                    }"
                    :should-render-suggestions="
                      (size, loading) => size >= 0 && !loading
                    "
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      {{ suggestion.item.name }}
                    </div>
                  </vue-autosuggest>
                  <b-form-invalid-feedback
                    :state="validateInstallment()"
                    id="input-installment-live-feedback"
                    >Vui lòng chọn dịch vụ trả góp</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group v-if="checkInstallmentAmount()">
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="installmentCode"
                    placeholder="Mã hợp đồng"
                  ></b-form-input>
                </b-form-group>
                <b-form-group v-if="checkInstallmentAmount()">
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="customerIndentifyNo"
                    placeholder="Số CMND"
                  ></b-form-input>
                </b-form-group>
                <div class="row">
                  <div class="col-md-4">
                    <p
                      :class="
                        titleFinal === 'Còn thiếu'
                          ? 'textTitleFinal'
                          : 'textExcessCash'
                      "
                    >
                      {{ titleFinal }}:
                    </p>
                  </div>
                  <div class="col-md-8">
                    <p class="text-right textFinal">
                      {{ convertPrice(totalAmount) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <b-container class="bv-example-row">
            <hr
              class="hr-text"
              data-content="Bút Toán"
              style="font-weight: 600"
            />
            <b-row>
              <b-col md="12" lg="12" sm="12">
                <BillTransactions :billNumber="$route.query.id" />
              </b-col>
            </b-row>
          </b-container>
        </b-form>
        <b-modal ref="add-product-bonus-modal" hide-footer :title="modalTitle">
          <v-form ref="form" lazy-validation>
            <b-form-group label="Chọn sản phẩm quà tặng:">
              <vue-autosuggest
                v-model="selectedProductBonus"
                :suggestions="filteredOptionsProduct"
                @selected="onSelectedProductBonus"
                :limit="10"
                @input="onInputChangeProductBonus"
                :input-props="{
                  id: 'autosuggest__input',
                  placeholder: 'Chọn sản phẩm quà tặng',
                }"
                :should-render-suggestions="
                  (size, loading) =>
                    size >= 0 && !loading && selectedProductBonus !== ''
                "
              >
                <div
                  slot-scope="{ suggestion }"
                  style="display: flex; align-items: center"
                >
                  <div>
                    <span class="text-primary">
                      ({{ formatMoney(suggestion.item.sellingPrice) }}) (
                    </span>
                    <span
                      v-if="suggestion.item.quantityInStock > 0"
                      class="text-success"
                      >Tồn TCB: {{ suggestion.item.quantityInStock }}</span
                    >
                    <span v-else class="text-danger"
                      >Tồn TCB: {{ suggestion.item.quantityInStock }}</span
                    >
                    )
                    {{ suggestion.item.productName }}
                  </div>
                </div>
              </vue-autosuggest>
            </b-form-group>
            <b-table
              :items="listProductBonus"
              :fields="fieldProductBonus"
              class="table-bordered table-hover col-md-12"
            >
              <template v-slot:cell(id)="row">
                <div>
                  <span
                    v-text="row.item.id"
                    class="d-flex justify-content-center"
                  ></span>
                </div>
              </template>
              <template v-slot:cell(price)="row">
                <div>
                  <span
                    v-text="convertPrice(row.item.price)"
                    class="text-right"
                  ></span>
                </div>
              </template>
              <template v-slot:cell(returnPrice)="row">
                <div>
                  <span
                    v-text="convertPrice(row.item.returnPrice)"
                    class="text-right"
                  ></span>
                </div>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <v-icon
                    small
                    class="text-danger"
                    @click="deleteProductBonus(row.item)"
                    v-b-tooltip
                    title="Xóa"
                    >mdi-delete</v-icon
                  >
                </div>
              </template>
            </b-table>
            <b-button
              style="fontweight: 600; width: 100px"
              variant="primary"
              size="sm"
              @click="addProductBonusToListBillItem"
              >Áp dụng</b-button
            >
            <b-button
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="hideModalAddProductBonus"
              >Hủy</b-button
            >
          </v-form>
        </b-modal>
        <b-modal
          ref="change-product-bonus-modal"
          hide-footer
          title="Đổi quà tặng"
        >
          <v-form ref="form" lazy-validation>
            <div v-for="(item, i) in listProductVariant" :key="i" class="mb-4">
              <input
                type="radio"
                v-model="choosedPro"
                :value="item.productId"
              />
              {{ item.productName }} | Giá bán:
              {{ convertPrice(item.sellingPrice) }}
            </div>
            <b-button
              style="fontweight: 600; width: 100px"
              variant="primary"
              size="sm"
              @click="onChangeProductBonus"
              >Áp dụng</b-button
            >
            <b-button
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="hideModalChangeProductBonus"
              >Hủy</b-button
            >
          </v-form>
        </b-modal>
        <b-modal
          ref="add-product-promotion-modal"
          hide-footer
          :title="modalTitle"
          size="xl"
        >
          <v-form ref="form" lazy-validation>
            <b-table
              :items="listProductPromotion"
              :fields="fieldProductPromotion"
              class="table-bordered table-hover col-md-12"
            >
              <template v-slot:head(selected)>
                <span>
                  <b-form-checkbox
                    v-model="checkAllPro"
                    size="lg"
                    @change="clickAll"
                  ></b-form-checkbox>
                </span>
              </template>
              <!-- check bõ -->
              <template v-slot:cell(selected)="row">
                <div class="d-flex justify-content-center">
                  <b-form-checkbox
                    size="lg"
                    v-model="row.item.checked"
                    @change="clickIndex(row.item.id)"
                  ></b-form-checkbox>
                </div>
              </template>
              <template v-slot:cell(id)="row">
                <div>
                  <span
                    v-text="row.item.id"
                    class="d-flex justify-content-center"
                  ></span>
                </div>
              </template>
              <template v-slot:cell(price)="row">
                <div>
                  <span
                    v-text="convertPrice(row.item.price)"
                    class="text-right"
                  ></span>
                </div>
              </template>
              <template v-slot:cell(returnPrice)="row">
                <div>
                  <span
                    v-text="convertPrice(row.item.returnPrice)"
                    class="text-right"
                  ></span>
                </div>
              </template>
            </b-table>
            <b-button
              style="fontweight: 600; width: 100px"
              variant="primary"
              size="sm"
              @click="addProductPromotionToListBillItem"
              >Áp dụng</b-button
            >
            <b-button
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="hideModalAddProductPromotion"
              >Hủy</b-button
            >
          </v-form>
        </b-modal>
        <b-modal
          ref="add-note-modal"
          hide-footer
          :title="'Thêm ghi chú cho sản phẩm ' + productNote"
        >
          <b-row class="mb-5">
            <b-col>
              <label class="labelInput" for="inputOriginalPrice">Ghi chú</label>
              <b-form-textarea
                v-model="noteBillItem"
                placeholder="Nhập ghi chú..."
                rows="5"
                max-rows="6"
              ></b-form-textarea>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="updateNote"
                >Lưu</b-button
              >
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                @click="hidenModalAddNote"
                >Hủy</b-button
              >
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          ref="add-product-warranty-modal"
          hide-footer
          :title="modalTitle"
          size="xl"
        >
          <v-form ref="form" lazy-validation>
            <b-table
              :items="listProductWarranty"
              :fields="fieldProductWarranty"
              :busy="onLoading"
              class="table-bordered table-hover col-md-12"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="4"></vcl-table>
              </template>
              <template v-slot:head(selected)>
                <span>
                  <b-form-checkbox
                    v-model="checkAllProWarranty"
                    size="lg"
                    @change="clickAllWarranty"
                  ></b-form-checkbox>
                </span>
              </template>
              <!-- check bõ -->
              <template v-slot:cell(selected)="row">
                <div class="d-flex justify-content-center">
                  <b-form-checkbox
                    size="lg"
                    v-model="row.item.checked"
                    @change="clickIndexWarranty()"
                  ></b-form-checkbox>
                </div>
              </template>
              <template v-slot:cell(id)="row">
                <div>
                  <span
                    v-text="row.item.id"
                    class="d-flex justify-content-center"
                  ></span>
                </div>
              </template>
              <template v-slot:cell(sellingPrice)="row">
                <div>
                  <span
                    v-text="convertPrice(row.item.sellingPrice)"
                    class="text-right"
                  ></span>
                </div>
              </template>
            </b-table>
            <b-button
              style="fontweight: 600; width: 100px"
              variant="primary"
              size="sm"
              @click="addProductWarrantyToListBillItem"
              >Lưu</b-button
            >
            <b-button
              style="margin-left: 10px; font-weight: 600; width: 70px"
              variant="secondary"
              size="sm"
              @click="hideModalAddProductWarranty"
              >Hủy</b-button
            >
          </v-form>
        </b-modal>
      </template>

      <template v-slot:foot>
        <b-container class="bv-example-row">
          <b-row v-if="checkPermission('BILL_UPDATE')">
            <b-col>
              <b-button
                tabindex="15"
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                type="submit"
                @click="updateBill"
                :disabled="onLoading"
                >Lưu</b-button
              >
              <router-link to="/wholesaleInvoices" tag="button">
                <b-button
                  tabindex="16"
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  >Hủy</b-button
                >
              </router-link>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <router-link to="/wholesaleInvoices" tag="button">
                <b-button
                  tabindex="16"
                  style="font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  >Trở về</b-button
                >
              </router-link>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </KTCodePreview>
  </div>
</template>
<style scoped>
#select-product {
  border-radius: 0px;
  /* border-bottom-right-radius:0px;
border-top-right-radius:0px; */
  box-shadow: none;
  border: 1px solid #ced4da;
}
.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
.input-style {
  border: 1px solid #ced4da;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #babac5;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #babac5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #babac5;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import BillItem from '../../components/BillItem.vue';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import ApiService from '@/core/services/api.service';
import { currencyMask, unMaskPrice } from './../../../utils/common';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import Swal from 'sweetalert2';
import { removeAccents } from './../../../utils/common';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import moment from 'moment';
import BillTransactions from '../../components/BillTransactions.vue';
import decounce from 'debounce';
import localData from '../../../utils/saveDataToLocal';
import { TIME_TRIGGER } from './../../../utils/constants';
import { xoa_dau } from './../../../utils/common';
import { AMOUNT_TYPE } from '../../../utils/enum';

export default {
  mixins: [validationMixin],
  data() {
    return {
      isOrdering: false,
      isValidImei: true,
      mask: currencyMask,
      customerShipFee: 0,
      shipFee: 0,
      listStore: [],
      selectedCustomerSource: null,
      listCustomerSource: [],
      listCity: [],
      selectCity: null,
      listDistrict: [],
      selectDistrict: null,
      listWard: [],
      selectWard: null,
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
        {
          id: 2,
          name: 'Tìm sản phẩm IMEI',
        },
      ],
      selectTypeSearch: 1,
      selectStatus: 1,
      listBillItem: [],
      discount: 0,
      customerName: '',
      transferAmount: 0,
      address: '',
      creditAmount: 0,
      creditCode: '',
      installedMoneyAmount: 0,
      cashAmount: 0,
      datePay: '',
      saleNote: '',
      dateCome: '',
      timeCome: '',
      search: '',
      noteCustomer: '',
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchStore: '',
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      searchEmployee: '',
      listCustomer: [],
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: [],
        },
      ],
      searchCustomer: '',
      selectDiscountType: AMOUNT_TYPE.MONEY,
      listDiscountType: [
        {
          id: AMOUNT_TYPE.MONEY,
          name: 'Tiền mặt',
        },
        {
          id: AMOUNT_TYPE.PERCENT,
          name: '%',
        },
      ],
      listProduct: [],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      codeShip: '',
      inputSearchProductProp: {
        id: 'autosuggest__input',
        placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        style: 'border-radius:0px!important',
        disabled: true,
      },
      selectedCreditAcc: '',
      filteredCashAccOptions: [],
      filteredCreditAccOptions: [],
      filteredAccOptions: [],
      cashAccOptions: [
        {
          data: [],
        },
      ],
      listCashAccSearch: [],
      creditAccOptions: [
        {
          data: [],
        },
      ],
      listCreditAccSearch: [],
      listAccSearch: [],
      selectedAccountant: '',
      accountantOptions: [
        {
          data: [],
        },
      ],
      cashAccount: '',
      transferAccount: '',
      saleName: '',
      technicalName: '',
      cashierName: '',
      listEmployeeSearch: [],
      listInstallment: [],
      filteredOptionsInstallment: [],
      optionsInstallment: [
        {
          data: [],
        },
      ],
      selectedInstallment: '',
      installmentCode: '',
      selectedCashAcc: '',
      isSubmit: false,
      dpConfigs: {
        dateContract: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
          allowInputToggle: true,
        },
      },
      dateContract: '',
      cityName: '',
      taxCode: '',
      contractCode: '',
      billId: null,
      billNumber: null,
      customerIndentifyNo: '',
      countProduct: 0,
      selectedProductBonus: '',
      fieldProductBonus: [
        {
          key: 'id',
          label: 'STT',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'name',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'quantityInStock',
          label: 'Tồn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'price',
          label: 'Giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'returnPrice',
          label: 'Giá thu lại',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        { key: 'actions', label: '' },
      ],
      listProductBonus: [],
      countProductBonus: 0,
      addProBonusForBillItemId: 0,
      modalTitle: '',
      choosedPro: '',
      listProductVariant: [],
      currentBonusProductId: null,
      proSTT: 0,
      fieldProductPromotion: [
        {
          key: 'selected',
          label: '',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'name',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'quantityInStock',
          label: 'Tồn',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'price',
          label: 'Giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'returnPrice',
          label: 'Giá chiết khấu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        { key: 'code', label: 'Mã BPK' },
      ],
      listProductPromotion: [],
      addProPromotionForBillItemId: null,
      countProductPromotion: 0,
      selectedProductPromotion: '',
      timer: null,
      checkAllPro: false,
      productNote: '',
      noteBillItem: '',
      productIdUpdateNote: null,
      onLoading: false,
      totalPoint: 0,
      pointUse: 0,
      otpCode: '',
      totalAmountPoint: 0,
      isSubtractPointValidated: false,
      pointUseOld: 0,
      typeSearch: 1,
      customerId: null,
      pointRate: 0,
      allowAddPointRoundToDown: false,
      isSearching: false,
      selectedCashAccount: null,
      selectedCashAccountCode: '',
      selectedClassAccountant: null,
      selectedClassAccountantCode: '',
      selectedClassCreditAcc: null,
      selectedClassCreditAccCode: '',
      selectedClassCreditFeeAcc: null,
      selectedClassCreditFeeAccCode: '',
      selectedInstallmentAccount: null,
      selectedInstallmentAccountCode: '',
      billDetailTitle: 'Chi tiết hóa đơn',
      customerReceptionName: '',
      assistantName: '',
      managerName: '',
      warrantyNote: '',
      transferReferenceCode: '',
      creditCardCode: '',
      filteredCreditFeeAccOptions: [],
      listCreditFeeAccSearch: [],
      creditFeeAccOptions: [
        {
          data: [],
        },
      ],
      creditFeeRate: 0,
      listPayCreditFeeType: [
        {
          id: 1,
          name: 'Tiền mặt',
        },
        {
          id: 2,
          name: 'Chuyển khoản',
        },
        {
          id: 3,
          name: 'Quẹt thẻ',
        },
      ],
      selectedPayCreditFeeType: 1,
      selectedCreditFeeAcc: '',
      creditCardFee: 0,
      transferFromOrder: 0,
      depositAmount: 0,
      moneyCredit: 0,
      moneyInstallment: 0,
      orderId: null,
      checkAllProWarranty: false,
      fieldProductWarranty: [
        {
          key: 'selected',
          label: '',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
      ],
      listProductWarranty: [],
      shipperName: '',
    };
  },
  validations: {
    searchStore: {
      required,
    },
    searchCustomer: {
      required,
    },
  },
  components: {
    KTCodePreview,
    BillItem,
    datePicker,
    BillTransactions,
  },
  computed: {
    totalAmount() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.billItemType === 1) {
          const totalPrice =
            unMaskPrice(element.price) * unMaskPrice(element.quantity);
          // Check discount type
          if (element.discountType == AMOUNT_TYPE.PERCENT) {
            let discountAmount = totalPrice * element.discount;
            total += totalPrice - (discountAmount / 100).toFixed(0);
          } else {
            total += totalPrice - unMaskPrice(element.discount);
          }
        } else if (element.billItemType === 2 && element.checked === false) {
          total -=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        } else if (element.billItemType === 3 && element.checked === true) {
          total +=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        }
      }
      let totalTransferAmount = 0;
      if (this.transferAmount !== '') {
        totalTransferAmount = unMaskPrice(this.transferAmount);
      }
      let totalCreditAmount = 0;
      if (this.creditAmount !== '') {
        totalCreditAmount = unMaskPrice(this.creditAmount);
      }
      let totalInstalledMoneyAmount = 0;
      if (this.installedMoneyAmount !== '') {
        totalInstalledMoneyAmount = unMaskPrice(this.installedMoneyAmount);
      }
      let totalCashAmount = 0;
      if (this.cashAmount !== '') {
        totalCashAmount = unMaskPrice(this.cashAmount);
      }
      let discountAmount = 0;
      if (this.discount !== '') {
        discountAmount = unMaskPrice(this.discount);
      }
      if (this.selectDiscountType == AMOUNT_TYPE.MONEY) {
        total = total - discountAmount;
      } else {
        total = total - (total * discountAmount) / 100;
      }
      let final =
        total -
        (parseInt(totalTransferAmount) +
          parseInt(totalCreditAmount) +
          parseInt(totalInstalledMoneyAmount) +
          parseInt(totalCashAmount) +
          parseInt(this.depositAmount) +
          parseInt(this.transferFromOrder) +
          parseInt(this.moneyCredit) +
          parseInt(this.moneyInstallment));
      let finalAmount = final - this.totalAmountPoint;
      if (final < 0) {
        finalAmount = final * -1;
      }
      return finalAmount;
    },
    titleFinal() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.billItemType === 1) {
          const totalPrice =
            unMaskPrice(element.price) * unMaskPrice(element.quantity);
          // Check discount type
          if (element.discountType == AMOUNT_TYPE.PERCENT) {
            let discountAmount = totalPrice * element.discount;
            total += totalPrice - (discountAmount / 100).toFixed(0);
          } else {
            total += totalPrice - unMaskPrice(element.discount);
          }
        } else if (element.billItemType === 2 && element.checked === false) {
          total -=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        } else if (element.billItemType === 3 && element.checked === true) {
          total +=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        }
      }
      let totalTransferAmount = 0;
      if (this.transferAmount !== '') {
        totalTransferAmount = unMaskPrice(this.transferAmount);
      }
      let totalCreditAmount = 0;
      if (this.creditAmount !== '') {
        totalCreditAmount = unMaskPrice(this.creditAmount);
      }
      let totalInstalledMoneyAmount = 0;
      if (this.installedMoneyAmount !== '') {
        totalInstalledMoneyAmount = unMaskPrice(this.installedMoneyAmount);
      }
      let totalCashAmount = 0;
      if (this.cashAmount !== '') {
        totalCashAmount = unMaskPrice(this.cashAmount);
      }
      let discountAmount = 0;
      if (this.discount !== '') {
        discountAmount = unMaskPrice(this.discount);
      }
      if (this.selectDiscountType == AMOUNT_TYPE.MONEY) {
        total = total - discountAmount;
      } else {
        total = total - (total * discountAmount) / 100;
      }
      let final =
        total -
        (parseInt(totalTransferAmount) +
          parseInt(totalCreditAmount) +
          parseInt(totalInstalledMoneyAmount) +
          parseInt(totalCashAmount) +
          parseInt(this.depositAmount) +
          parseInt(this.transferFromOrder) +
          parseInt(this.moneyCredit) +
          parseInt(this.moneyInstallment)) -
        this.totalAmountPoint;
      if (final <= 0) {
        return 'Tiền thừa';
      } else {
        return 'Còn thiếu';
      }
    },
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.billItemType === 1) {
          const totalPrice =
            unMaskPrice(element.price) * unMaskPrice(element.quantity);
          // Check discount type
          if (element.discountType == AMOUNT_TYPE.PERCENT) {
            let discountAmount = totalPrice * element.discount;
            total += totalPrice - (discountAmount / 100).toFixed(0);
          } else {
            total += totalPrice - unMaskPrice(element.discount);
          }
        } else if (element.billItemType === 2 && element.checked === false) {
          total -=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        } else if (element.billItemType === 3 && element.checked === true) {
          total +=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        }
      }
      let discountAmount = 0;
      if (this.discount !== '') {
        discountAmount = unMaskPrice(this.discount);
      }
      if (this.selectDiscountType == AMOUNT_TYPE.MONEY) {
        total = total - discountAmount;
      } else {
        total = total - (total * discountAmount) / 100;
      }
      total =
        total -
        this.totalAmountPoint -
        (parseInt(this.depositAmount) +
          parseInt(this.transferFromOrder) +
          parseInt(this.moneyCredit) +
          parseInt(this.moneyInstallment));
      if (total < 0) {
        total = 0;
      }
      return total;
    },
    selectedClassStore() {
      const store = this.listStore.find((s) => s.name === this.searchStore);
      if (store) {
        return store.id;
      } else {
        return null;
      }
    },
    selectedClassEmployee() {
      const employee = this.listEmployee.find(
        (s) => s.fullName === this.searchEmployee
      );
      if (employee) {
        return employee.id;
      } else {
        return null;
      }
    },
    selectedClassCustomer() {
      const customer = this.listCustomer.find(
        (s) => s.phoneNo === this.searchCustomer
      );
      if (customer) {
        return customer.id;
      } else {
        return this.searchCustomer;
      }
    },
    selectedClassProduct() {
      const product = this.listProduct.find(
        (p) => p.productName === this.searchProduct
      );
      if (product) {
        return product;
      } else {
        return null;
      }
    },
    selectedEmployeeSale() {
      const employee = this.listEmployeeSearch.find(
        (e) => e.fullName === this.saleName
      );
      if (employee) {
        return employee.id;
      } else {
        return null;
      }
    },
    selectedEmployeeTechnical() {
      const employee = this.listEmployeeSearch.find(
        (e) => e.fullName === this.technicalName
      );
      if (employee) {
        return employee.id;
      } else {
        return null;
      }
    },
    selectedEmployeeCashier() {
      const employee = this.listEmployeeSearch.find(
        (e) => e.fullName === this.cashierName
      );
      if (employee) {
        return employee.id;
      } else {
        return null;
      }
    },
    selectedEmployeeManager() {
      const employee = this.listEmployeeSearch.find(
        (e) => e.fullName === this.managerName
      );
      if (employee) {
        return employee.id;
      } else {
        return null;
      }
    },
    selectedEmployeeAssistant() {
      const employee = this.listEmployeeSearch.find(
        (e) => e.fullName === this.assistantName
      );
      if (employee) {
        return employee.id;
      } else {
        return null;
      }
    },
    selectedEmployeeCustomerReception() {
      const employee = this.listEmployeeSearch.find(
        (e) => e.fullName === this.customerReceptionName
      );
      if (employee) {
        return employee.id;
      } else {
        return null;
      }
    },
    selectedShipper() {
      const employee = this.listEmployeeSearch.find(
        (e) => e.fullName === this.shipperName
      );
      if (employee) {
        return employee.id;
      } else {
        return null;
      }
    },
    totalAmountReturn() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.billItemType === 2 && element.checked === false) {
          total +=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        }
      }
      return total;
    },
    totalMoney() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.billItemType === 1) {
          const totalPrice =
            unMaskPrice(element.price) * unMaskPrice(element.quantity);
          // Check discount type
          if (element.discountType == AMOUNT_TYPE.PERCENT) {
            let discountAmount = totalPrice * element.discount;
            total += totalPrice - (discountAmount / 100).toFixed(0);
          } else {
            total += totalPrice - unMaskPrice(element.discount);
          }
        } else if (element.billItemType === 3 && element.checked === true) {
          total +=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        }
      }
      let discountAmount = 0;
      if (this.discount !== '') {
        discountAmount = unMaskPrice(this.discount);
      }
      if (this.selectDiscountType == AMOUNT_TYPE.MONEY) {
        total = total - discountAmount;
      } else {
        total = total - (total * discountAmount) / 100;
      }
      if (total < 0) {
        total = 0;
      }
      return total;
    },
    totalPointAdd() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.billItemType === 1) {
          const totalPrice =
            unMaskPrice(element.price) * unMaskPrice(element.quantity);
          // Check discount type
          if (element.discountType == AMOUNT_TYPE.PERCENT) {
            let discountAmount = totalPrice * element.discount;
            total += totalPrice - (discountAmount / 100).toFixed(0);
          } else {
            total += totalPrice - unMaskPrice(element.discount);
          }
        } else if (element.billItemType === 2 && element.checked === false) {
          total -=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        } else if (element.billItemType === 3 && element.checked === true) {
          total +=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        }
      }
      let discountAmount = 0;
      if (this.discount !== '') {
        discountAmount = unMaskPrice(this.discount);
      }
      if (this.selectDiscountType == AMOUNT_TYPE.MONEY) {
        total = total - discountAmount;
      } else {
        total = total - (total * discountAmount) / 100;
      }
      total = total - this.totalAmountPoint;
      if (total < 0) {
        total = 0;
      }
      let point = parseInt(total / this.pointRate);
      let result = 0;
      if (this.allowAddPointRoundToDown) {
        result = point;
      } else {
        if (total % this.pointRate >= 5) {
          result = point + 1;
        } else {
          result = point;
        }
      }
      return result;
    },
  },
  created() {
    this.fetchCity();
    this.fetchStore();
    this.getListOrderSource();
    this.getListEmployee();
    this.fetchInstallments();
    this.fetchCashAcc();
    this.getCreditFee();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn bán buôn', route: '/wholesaleInvoices' },
      { title: 'Danh sách hóa đơn bán buôn', route: '/wholesaleInvoices' },
      { title: 'Cập nhật hóa đơn bán buôn' },
    ]);
    this.billNumber = this.$route.query.id;
    this.getBillById();
  },
  methods: {
    getOrderByCustomer: async function (customerId) {
      let params = {
        customerId: customerId,
      };
      await ApiService.setHeader();
      await ApiService.query('orders/getOrder/getOrderByUser', {
        params,
      }).then((response) => {
        let { status } = response.data.data;
        if (status === 1 || status === 2 || status === 3) {
          this.showConfirmBox();
        }
      });
    },
    checkValidImei(value) {
      this.isValidImei = value;
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    onSelectedStore(option) {
      this.searchStore = option.item;
      this.listBillItem = [];
      this.inputSearchProductProp.disabled = false;
      this.fetchAccountant();
      this.fetchCreditAcc();
      this.fetchInstallments();
      this.fetchCashAcc();
      this.transferAmount = 0;
      this.creditAmount = 0;
      this.installedMoneyAmount = 0;
      this.cashAmount = 0;
      this.discount = 0;
      this.selectedCashAcc = '';
      this.selectedInstallment = '';
      this.selectedCreditAcc = '';
      this.selectedAccountant = '';
      this.installmentCode = '';
      this.creditCode = '';
    },
    onSelectedSale(option) {
      this.saleName = option.item;
    },
    onSelectedTechnical(option) {
      this.technicalName = option.item;
    },
    onSelectedCash(option) {
      this.cashierName = option.item;
    },
    onSelectedCustomer(option) {
      this.searchCustomer = option.item;
      this.getCustomerInfoById();
      //this.getOrderByCustomer(this.selectedClassCustomer);
    },
    onSelectedProduct(option) {
      this.searchProduct = '';
      let selectedProduct = option.item;
      let imeiCode = '';
      let productType = selectedProduct.productType;
      if (this.selectTypeSearch === 2) {
        imeiCode = selectedProduct.imeiCode;
        productType = 2;
      }

      let billItem = {
        id: 0,
        productId: selectedProduct.productId,
        name: selectedProduct.productName,
        code: selectedProduct.productCode,
        price: selectedProduct.sellingPrice,
        type: productType,
        quantity: 1,
        imeiCode: imeiCode,
        totalPrice: selectedProduct.sellingPrice,
        discount: 0,
        quantityInStock: selectedProduct.quantityInStock,
        storeId: this.selectedClassStore,
        comboItems: selectedProduct.listProductInCombo,
        billItemType: 1,
        checked: true,
        listAccessoryPromotion: selectedProduct.listAccessoryPromotion,
        returnPrice: 0,
        accessoryGroupId: '',
        accessoryGroupCode: '',
        stt: 0,
        note: '',
        discountType: AMOUNT_TYPE.PERCENT,
        discountProgramId: -1,
      };
      if (this.selectTypeSearch === 2) {
        if (this.isExistImeiCode(this.listBillItem, imeiCode)) {
          this.makeToastFaile('Mã IMEI đã tồn tại trong hóa đơn');
        } else {
          this.countProduct++;
          this.proSTT++;
          billItem.stt = this.proSTT;
          billItem.id = this.countProduct;
          this.listBillItem.push(billItem);
          selectedProduct.listAccessoryBonus.forEach((element) => {
            let code = `Quà tặng kèm- ${element.accessoryGroupCode}`;
            this.countProduct++;
            let accessoryBonus = {
              id: this.countProduct,
              productId: element.productId,
              name: element.productName,
              code: code,
              price: element.sellingPrice,
              quantity: 1,
              type: element.productType,
              imeiCode: '',
              totalPrice: element.sellingPrice,
              discount: '',
              quantityInStock: element.quantityInStock,
              storeId: this.selectedClassStore,
              billItemType: 2,
              checked: true,
              parentId: billItem.id,
              returnPrice: element.price,
              accessoryGroupId: element.accessoryGroupId,
              accessoryGroupCode: element.accessoryGroupCode,
              listProductVariant: element.listProductVariant,
            };
            this.listBillItem.push(accessoryBonus);
          });
        }
      } else {
        if (
          selectedProduct.quantityInStock > 0 ||
          selectedProduct.productType === 4
        ) {
          this.countProduct++;
          this.proSTT++;
          billItem.stt = this.proSTT;
          billItem.id = this.countProduct;
          this.listBillItem.push(billItem);
          selectedProduct.listAccessoryBonus.forEach((element) => {
            let code = `Quà tặng kèm- ${element.accessoryGroupCode}`;
            this.countProduct++;
            let accessoryBonus = {
              id: this.countProduct,
              productId: element.productId,
              name: element.productName,
              code: code,
              price: element.sellingPrice,
              quantity: 1,
              type: element.productType,
              imeiCode: '',
              totalPrice: element.sellingPrice,
              discount: '',
              quantityInStock: element.quantityInStock,
              storeId: this.selectedClassStore,
              billItemType: 2,
              checked: true,
              parentId: billItem.id,
              returnPrice: element.price,
              accessoryGroupId: element.accessoryGroupId,
              accessoryGroupCode: element.accessoryGroupCode,
              listProductVariant: element.listProductVariant,
            };
            this.listBillItem.push(accessoryBonus);
          });
        } else {
          this.makeToastFaile('Số sản phẩm tồn trong kho không đủ!');
        }
      }
      this.listBillItem = this.sortListBillItem(this.listBillItem);
    },
    validateState(name) {
      if (this.isSubmit) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchCity() {
      ApiService.setHeader();
      ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
      });
    },
    getDistrict() {
      ApiService.setHeader();
      ApiService.get(`city/${this.selectCity}/district`).then((data) => {
        this.listDistrict = data.data.data;
      });
    },
    getWard() {
      // this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectDistrict}/ward`).then((data) => {
        this.listWard = data.data.data;
      });
    },
    onInputChange(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = false;
      }
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
      // this.getProductStock();
    },
    onInputChangeSale(text) {
      if (!text) {
        text = '';
      }
      this.saleName = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item);
          return (
            item.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onInputChangeTechnical(text) {
      if (!text) {
        text = '';
      }
      this.technicalName = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item);
          return (
            item.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onInputChangeCash(text) {
      if (!text) {
        text = '';
      }
      this.cashierName = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item);
          return (
            item.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onInputChangCustomer(text) {
      if (!text) {
        text = '';
      }
      this.searchCustomer = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      this.optionsCustomer[0].data = [];
      this.filteredOptionsCustomer = [
        {
          data: this.optionsCustomer[0].data,
        },
      ];
      this.debounceInput();
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.optionsProduct[0].data = [];
      this.filteredOptionsProduct = [
        {
          data: this.optionsProduct[0].data,
        },
      ];
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    fetchStore: async function () {
      this.options[0].data = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.listStore = stores;
        stores.map((element) => {
          let storeName = element.name;
          this.options[0].data.push(storeName);
        });
        let tmp = [{ data: this.options[0].data }];
        this.filteredOptions = tmp;
      });
    },
    getListOrderSource() {
      ApiService.setHeader();
      ApiService.get(`orderSources/getAll`).then((data) => {
        this.listCustomerSource = data.data.data;
      });
    },
    getListEmployee() {
      ApiService.setHeader();
      ApiService.get('employees/getAll').then((data) => {
        const employees = data.data.data;
        this.listEmployeeSearch = employees;
        employees.map((element) => {
          let employeeName = element.fullName;
          this.optionsEmployee[0].data.push(employeeName);
        });
      });
    },
    getListCustomer() {
      ApiService.setHeader();
      ApiService.get(
        `customer/get-by-phone?phoneNumber=${this.searchCustomer}`
      ).then((data) => {
        const customers = data.data.data;
        this.listCustomer = customers;
        customers.map((element) => {
          let cus = element.phoneNo;
          this.optionsCustomer[0].data.push(cus);
        });
        this.filteredOptionsCustomer = [
          {
            data: this.optionsCustomer[0].data,
          },
        ];
      });
    },
    getCustomerInfoById() {
      ApiService.setHeader();
      ApiService.get(`customer/${this.selectedClassCustomer}`).then((data) => {
        let customerInfo = data.data.data;
        this.customerName = customerInfo.fullName;
        this.selectCity = customerInfo.city;
        this.selectWard = customerInfo.ward;
        this.selectDistrict = customerInfo.district;
        this.address = customerInfo.address;
        if (this.selectCity !== null) {
          this.getDistrict();
        }
        if (this.selectDistrict !== null) {
          this.getWard();
        }
      });
    },
    changePlaceholder() {
      if (this.selectTypeSearch === 1) {
        this.inputSearchProductProp.placeholder =
          'Tìm kiếm theo tên, mã, mã vạch sản phẩm';
      } else if (this.selectTypeSearch === 2) {
        this.inputSearchProductProp.placeholder =
          'Tìm kiếm theo mã IMEI sản phẩm';
      }
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    fetchProduct(textSearch, searchFor) {
      this.isSearching = true;
      this.listProduct = [];
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch?storeId=${this.selectedClassStore}&searchProduct=${textSearch}`
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          let suggestName = `(${this.convertPrice(
            element.sellingPrice
          )}) (Tồn CTB: ${element.quantityInStock}) ${element.productCode} - ${
            element.productName
          }`;
  const sellingPrice =
            element.wholesalePrice && element.wholesalePrice !== 0
              ? element.wholesalePrice
              : element.sellingPrice;
          const item = {
            productId: element.productId,
            productName: element.productName,
            productCode: element.productCode,
            barCode: element.barCode,
            quantityInStock: element.quantityInStock,
            sellingPrice: sellingPrice,
            returnSellingPrice: element.returnSellingPrice,
            suggestName: suggestName,
            productType: element.productType,
            listProductInCombo: element.listProductInCombo,
            listAccessoryBonus: element.listAccessoryBonus,
            listAccessoryPromotion: element.listAccessoryPromotion,
          };
          this.optionsProduct[0].data.push(item);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (searchFor === 1) {
          if (textSearch !== this.searchProduct) {
            this.searchProductAPI();
          }
        } else if (searchFor === 2) {
          if (textSearch !== this.selectedProductBonus) {
            this.fetchProduct(this.selectedProductBonus, 2);
          }
        }
      });
    },
    deleteItemInArray(id) {
      let listNew = this.listBillItem.filter((billItem) => billItem.id !== id);
      this.listBillItem = [];
      listNew.forEach((element) => {
        this.listBillItem.push(element);
      });
      let deleteProductBonus = this.listBillItem.filter(
        (billItem) => billItem.parentId !== id
      );
      this.listBillItem = [];
      deleteProductBonus.forEach((element) => {
        this.listBillItem.push(element);
      });
      this.proSTT = 0;
      this.listBillItem.forEach((element, index) => {
        if (element.billItemType === 1) {
          this.proSTT++;
          this.listBillItem[index].stt = this.proSTT;
        }
      });
    },
    updateItem(item) {
      this.listBillItem.forEach((element, index) => {
        if (element.id === item.id) {
          this.listBillItem[index].name = item.name;
          this.listBillItem[index].imeiCode = item.imeiCode;
          this.listBillItem[index].proId = item.proId;
          this.listBillItem[index].price = item.price
            ? unMaskPrice(item.price)
            : 0;
          this.listBillItem[index].quantity = item.quantity
            ? unMaskPrice(item.quantity)
            : 1;
          this.listBillItem[index].totalPrice = item.totalPrice;
          this.listBillItem[index].discount = item.discount
            ? unMaskPrice(item.discount)
            : 0;
          this.listBillItem[index].discountType = item.discountType;
          this.listBillItem[index].discountProgramId = item.discountProgramId;
        }
      });
    },
    showConfirmBox: function () {
      Swal.fire({
        title: 'Xác nhận đơn hàng!',
        text: 'Khách hàng này hiện có đơn hàng đang xử lý, tiếp tục tạo mới đơn hàng ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3699FF',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          this.searchCustomer = '';
          this.customerName = '';
          this.selectCity = '';
          this.selectWard = '';
          this.selectDistrict = '';
          this.address = '';
        }
      });
    },
    updateBill() {
      if (this.listBillItem.length <= 0 || this.listBillItem === []) {
        this.makeToastFaile('Bạn chưa chọn sản phẩm nào');
        return;
      }
      let listPro = [];
      this.listBillItem.forEach((element) => {
        let item = {
          id: element.id,
          productId: element.productId,
          productPrice: element.price ? unMaskPrice(element.price) : 0,
          quantity: element.quantity ? unMaskPrice(element.quantity) : 1,
          discountAmount: element.discount ? unMaskPrice(element.discount) : 0,
          imeiCode: element.imeiCode,
          productType: element.billItemType,
          accessoryGroupId: element.accessoryGroupId,
          accessoryGroupCode: element.accessoryGroupCode,
          repurchasePrice: element.returnPrice
            ? unMaskPrice(element.returnPrice)
            : 0,
          isGiftTaken: element.checked,
          note: element.note,
          belongBillItemId: element.parentId,
          discountType: element.discountType,
          discountProgramId: element.discountProgramId,
        };
        listPro.push(item);
      });
      let cusphone = '';
      const customer = this.listCustomer.find(
        (s) => s.phoneNo === this.searchCustomer
      );
      if (customer) {
        cusphone = customer.phoneNo;
      } else {
        cusphone = this.searchCustomer;
      }
      let contractDate = this.dateContract
        ? moment(this.dateContract, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null;
      let data = {
        id: this.billId,
        customerPhone: cusphone,
        customerName: this.customerName,
        customerAddress: this.address,
        customerDistrict: this.selectDistrict,
        customerCity: this.selectCity,
        customerWard: this.selectWard,
        storeId: this.selectedClassStore,
        saleId: this.selectedEmployeeSale,
        technicalId: this.selectedEmployeeTechnical,
        cashierId: this.selectedEmployeeCashier,
        status: this.selectStatus,
        customerNote: this.noteCustomer,
        saleNote: this.saleNote,
        discountType: this.selectDiscountType,
        discountAmount: unMaskPrice(this.discount),
        transferAmount: this.transferAmount
          ? unMaskPrice(this.transferAmount)
          : 0,
        transferAccountID: this.selectedClassAccountant,
        creditAccountId: this.selectedClassCreditAcc,
        creditAmount: this.creditAmount ? unMaskPrice(this.creditAmount) : 0,
        creditCode: this.creditCode,
        installedMoneyAmount: this.installedMoneyAmount
          ? unMaskPrice(this.installedMoneyAmount)
          : 0,
        installMoneyCode: this.installmentCode,
        installMoneyAccountId: this.selectedInstallmentAccount,
        cashAccountId: this.selectedCashAccount,
        cashAmount: this.cashAmount ? unMaskPrice(this.cashAmount) : 0,
        listBillItem: listPro,
        orderSourceId: this.selectedCustomerSource,
        companyName: this.cityName,
        taxCode: this.taxCode,
        contractCode: this.contractCode,
        contractDate: contractDate,
        customerIndentifyNo: this.customerIndentifyNo,
        cashAccountCode: this.selectedCashAccountCode,
        transferAccountCode: this.selectedClassAccountantCode,
        creditFeeAcountCode: this.selectedClassCreditFeeAccCode,
        creditAccountCode: this.selectedClassCreditAccCode,
        installMoneyAccountCode: this.selectedInstallmentAccountCode,
        shipperId: this.selectedShipper,
      };
      this.onLoading = true;
      //alert(`List bill item: ${JSON.stringify(data)}`);
      ApiService.setHeader();
      ApiService.put('bills/wholeSaleInvoice', data)
        .then(({ data }) => {
          this.onLoading = false;
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            setTimeout(() => {
              this.$router.push({
                path: '/wholesaleInvoices',
              });
            }, 1000);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.onLoading = false;
          this.makeToastFaile(response.$error);
        });
    },
    fetchProductImei(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      ApiService.get(
        `productSearch/search-by-imei?storeId=${this.selectedClassStore}&imeiCode=${textSearch}`
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          let suggestName = `(${this.convertPrice(element.sellingPrice)}) ${
            element.productCode
          } - ${element.productName} - IMEI: ${element.imeiCode}`;
          const sellingPrice =
            element.wholesalePrice && element.wholesalePrice !== 0
              ? element.wholesalePrice
              : element.sellingPrice;
          const item = {
            productId: element.productId,
            productName: element.productName,
            productCode: element.productCode,
            sellingPrice: sellingPrice,
            suggestName: suggestName,
            productType: element.productType,
            imeiCode: element.imeiCode,
            listAccessoryBonus: element.listAccessoryBonus,
            listAccessoryPromotion: element.listAccessoryPromotion,
          };
          this.optionsProduct[0].data.push(item);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          this.searchProductAPI();
        }
      });
    },
    isExistImeiCode(listProduct, imeiCode) {
      let check = false;
      for (let index = 0; index < listProduct.length; index++) {
        const element = listProduct[index];
        if (element.imeiCode === imeiCode) {
          check = true;
          break;
        }
      }
      return check;
    },
    checkTransferAmount() {
      return unMaskPrice(this.transferAmount) > 0;
    },
    checkCreditPrice() {
      return unMaskPrice(this.creditAmount) > 0;
    },
    checkInstallmentAmount() {
      return unMaskPrice(this.installedMoneyAmount) > 0;
    },
    checkCashAmount() {
      return unMaskPrice(this.cashAmount) > 0;
    },
    fetchAccountant: async function () {
      ApiService.setHeader();
      ApiService.setHeader();
      let params = {
        //  tk ngan hang
        type: 2,
      };
      ApiService.query('accountants/getByType', { params }).then((response) => {
        this.accountantOptions[0].data = [];
        response.data.data.forEach((item) => {
          if (item.level >= 2) {
            let acc = {
              id: item.id,
              name: item.name,
              code: item.code,
            };
            this.listAccSearch.push(acc);
            this.accountantOptions[0].data.push(acc);
            this.creditAccOptions[0].data.push(acc);
          }
        });
        let tmp = [{ data: this.accountantOptions[0].data }];
        this.filteredAccOptions = tmp;
        if (
          this.selectedPayCreditFeeType === 2 ||
          this.selectedPayCreditFeeType === 3
        ) {
          this.listCreditFeeAccSearch = this.listAccSearch;
          this.creditFeeAccOptions[0].data = this.accountantOptions[0].data;
          let tmp = [{ data: this.creditFeeAccOptions[0].data }];
          this.filteredCreditFeeAccOptions = tmp;
        }
      });
    },
    onInputCreditAccChange(text) {
      this.selectedCreditAcc = text;
      const filteredData = this.creditAccOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCreditAccOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onInputCashAccChange(text) {
      this.selectedCashAcc = text;
      const filteredData = this.cashAccOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCashAccOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onCashAccSelected(option) {
      this.selectedCashAcc = option.item.name;
      this.selectedCashAccount = option.item.id;
      this.selectedCashAccountCode = option.item.code;
    },
    onCreditAccSelected(option) {
      this.selectedCreditAcc = option.item.name;
      this.selectedClassCreditAcc = option.item.id;
      this.selectedClassCreditAccCode = option.item.code;
    },
    onInputInstallmentChange(text) {
      this.selectedInstallment = text;
      const filteredData = this.optionsInstallment[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsInstallment = [
        {
          data: filteredData,
        },
      ];
    },
    onInstallmentSelected(option) {
      this.selectedInstallment = option.item.name;
      this.selectedInstallmentAccount = option.item.id;
      this.selectedInstallmentAccountCode = option.item.code;
    },
    onInputAccChange(text) {
      this.selectedAccountant = text;

      const filteredData = this.accountantOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredAccOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onAccSelected(option) {
      this.selectedAccountant = option.item.name;
      this.selectedClassAccountant = option.item.id;
      this.selectedClassAccountantCode = option.item.code;
    },
    fetchCreditAcc: async function () {
      ApiService.setHeader();
      let params = {
        //  tk quet the
        type: 2,
      };
      ApiService.query('accountants/getByType', { params }).then((response) => {
        this.creditAccOptions[0].data = [];
        response.data.data.forEach((item) => {
          if (item.level >= 2) {
            let acc = {
              id: item.id,
              name: item.name,
              code: item.code,
            };
            this.listCreditAccSearch.push(acc);
            this.creditAccOptions[0].data.push(acc);
          }
        });
        let tmp = [{ data: this.creditAccOptions[0].data }];
        this.filteredCreditAccOptions = tmp;
      });
    },
    fetchCashAcc: async function () {
      this.listCashAccSearch = [];
      ApiService.setHeader();
      if (this.selectedClassStore !== null) {
        let params = {
          storeId: this.selectedClassStore,
          //  tk tien mat
          type: 1,
        };
        ApiService.query('accountants/getByStore', { params }).then(
          (response) => {
            this.cashAccOptions[0].data = [];
            response.data.data.forEach((item) => {
              if (item.level >= 2) {
                let acc = {
                  id: item.id,
                  name: item.name,
                  code: item.code,
                };
                this.listCashAccSearch.push(acc);
                this.cashAccOptions[0].data.push(acc);
              }
            });
            let tmp = [{ data: this.cashAccOptions[0].data }];
            this.filteredCashAccOptions = tmp;
            if (this.selectedPayCreditFeeType === 1) {
              this.listCreditFeeAccSearch = this.listCashAccSearch;
              this.creditFeeAccOptions[0].data = this.cashAccOptions[0].data;
              let tmp = [{ data: this.creditFeeAccOptions[0].data }];
              this.filteredCreditFeeAccOptions = tmp;
            }
          }
        );
      } else {
        let params = {
          //  tk tien mat
          type: 1,
        };
        ApiService.query('accountants/getByType', { params }).then(
          (response) => {
            this.cashAccOptions[0].data = [];
            response.data.data.forEach((item) => {
              if (item.level >= 2) {
                let acc = {
                  id: item.id,
                  name: item.name,
                  code: item.code,
                };
                this.listCashAccSearch.push(acc);
                this.cashAccOptions[0].data.push(acc);
              }
            });
            let tmp = [{ data: this.cashAccOptions[0].data }];
            this.filteredCashAccOptions = tmp;
            if (this.selectedPayCreditFeeType === 1) {
              this.listCreditFeeAccSearch = this.listCashAccSearch;
              this.creditFeeAccOptions[0].data = this.cashAccOptions[0].data;
              let tmp = [{ data: this.creditFeeAccOptions[0].data }];
              this.filteredCreditFeeAccOptions = tmp;
            }
          }
        );
      }
    },
    fetchInstallments: async function () {
      let params = {
        page: 1,
        pageSize: 1000,
      };
      this.listInstallment = [];
      ApiService.setHeader();
      ApiService.query('/installments', { params }).then((response) => {
        this.listInstallment = response.data.data.result;
        this.optionsInstallment[0].data = [];
        this.listInstallment.forEach((element) => {
          this.optionsInstallment[0].data.push(element);
        });
        let tmp = [{ data: this.optionsInstallment[0].data }];
        this.filteredOptionsInstallment = tmp;
      });
    },
    isValidImeiCode(listBillItem) {
      let check = true;
      for (let index = 0; index < listBillItem.length; index++) {
        const element = listBillItem[index];
        if (element.type === 2 && element.imeiCode.trim() === '') {
          check = false;
          break;
        }
      }
      return check;
    },
    validateTransfer() {
      let check = true;
      if (
        unMaskPrice(this.transferAmount) > 0 &&
        this.selectedAccountant.trim() === '' &&
        this.isSubmit
      ) {
        check = false;
      }
      return check;
    },
    validateCredit() {
      let check = true;
      if (
        unMaskPrice(this.creditAmount) > 0 &&
        this.selectedCreditAcc.trim() === '' &&
        this.isSubmit
      ) {
        check = false;
      }
      return check;
    },
    validateInstallment() {
      let check = true;
      if (
        unMaskPrice(this.installedMoneyAmount) > 0 &&
        this.selectedInstallment.trim() === '' &&
        this.isSubmit
      ) {
        check = false;
      }
      return check;
    },
    validateCash() {
      let check = true;
      if (
        unMaskPrice(this.cashAmount) > 0 &&
        this.selectedCashAcc.trim() === '' &&
        this.isSubmit
      ) {
        check = false;
      }
      return check;
    },
    validateCustomer() {
      let check = true;
      if (this.searchCustomer.trim() === '' && this.isSubmit) {
        check = false;
      }
      return check;
    },
    getBillById() {
      ApiService.setHeader();
      ApiService.get(`bills/${this.billNumber}`).then((data) => {
        const storeId = data.data.data.storeId;
        data.data.data.billItems.forEach((element) => {
          if (element.billItemType === 1) {
            this.proSTT++;
            let billItem = {
              id: element.id,
              productId: element.productId,
              name: element.productName,
              code: element.productCode,
              price: element.productPrice,
              type: element.productType,
              quantity: element.quantity,
              imeiCode: element.imeiCode,
              totalPrice:
                element.quantity * element.productPrice -
                element.discountAmount,
              discount: element.discountAmount,
              comboItems: element.listProductInCombo,
              billItemType: 1,
              checked: true,
              returnPrice: 0,
              accessoryGroupId: '',
              accessoryGroupCode: '',
              stt: this.proSTT,
              note: element.note,
              parentId: element.belongBillDetailId,
              listAccessoryPromotion: element.listAccessoryPromotion,
              quantityInStock: element.quantityInStock,
              storeId: storeId,
              discountType: element.discountType + '',
              discountProgramId: element.discountProgramId,
            };
            this.listBillItem.push(billItem);
          } else {
            let code = `Quà tặng kèm- ${element.accessoryGroupCode}`;
            if (element.billItemType === 3) {
              code = `SP bán kèm- ${element.accessoryGroupCode}`;
            }
            let billItem = {
              id: element.id,
              productId: element.productId,
              name: element.productName,
              code: code,
              price: element.productPrice,
              type: element.productType,
              quantity: element.quantity,
              imeiCode: element.imeiCode,
              totalPrice:
                element.quantity * element.productPrice -
                element.discountAmount,
              discount: element.discountAmount,
              billItemType: element.billItemType,
              checked: element.isGiftTaken,
              returnPrice: element.repurchasePrice,
              accessoryGroupId: element.accessoryGroupId,
              accessoryGroupCode: element.accessoryGroupCode,
              parentId: element.belongBillDetailId,
              quantityInStock: element.quantityInStock,
              storeId: storeId,
              discountType: element.discountType + '',
              discountProgramId: element.discountProgramId,
            };
            this.listBillItem.push(billItem);
          }
        });
        this.saleNote = data.data.data.saleNote;
        this.noteCustomer = data.data.data.customerNote;
        this.saleName = data.data.data.saleName;
        this.technicalName = data.data.data.techName;
        this.cashierName = data.data.data.cashierName;
        if (this.cashierName === '') {
          this.fetchStoreByUser();
        }
        this.searchCustomer = data.data.data.customerPhone;
        this.address = data.data.data.customerAddress;
        this.customerName = data.data.data.customerName;
        this.selectCity = data.data.data.customerCity;
        this.selectWard = data.data.data.customerWard;
        this.selectDistrict = data.data.data.customerDistrict;
        this.fetchCity();
        if (this.selectCity !== null) {
          this.getDistrict();
        }
        if (this.selectDistrict !== null) {
          this.getWard();
        }
        this.searchStore = data.data.data.storeName;
        this.cashAmount = data.data.data.cashAmount;
        this.transferAmount = data.data.data.transferAmount;
        this.creditAmount = data.data.data.creditAmount;
        this.installedMoneyAmount = data.data.data.installedMoneyAmount;
        this.installmentCode = data.data.data.installMoneyCode;
        this.creditCode = data.data.data.creditCode;
        this.selectedAccountant = data.data.data.transferAccName;
        this.selectedCreditAcc = data.data.data.creditAccName;
        this.selectedInstallment = data.data.data.installmentAccName;
        this.selectedCashAcc = data.data.data.cashAccName;
        this.selectedCustomerSource = data.data.data.orderSourceId;
        this.discount = data.data.data.discountAmount;
        this.selectDiscountType = data.data.data.discountType;
        this.billId = data.data.data.id;
        this.cityName = data.data.data.companyName;
        this.taxCode = data.data.data.taxCode;
        this.contractCode = data.data.data.contractCode;
        this.dateContract = data.data.data.contractDate
          ? moment(data.data.data.contractDate).format('DD/MM/YYYY')
          : null;
        this.customerIndentifyNo = data.data.data.customerIndentifyNo;
        this.fetchAccountant();
        this.fetchCreditAcc();
        this.fetchInstallments();
        this.fetchCashAcc();
        this.inputSearchProductProp.disabled = false;
        this.selectedCashAccount = data.data.data.cashAccountId;
        this.selectedClassAccountant = data.data.data.transferAccountId;
        this.selectedClassCreditAcc = data.data.data.creditAccountId;
        this.selectedClassCreditFeeAcc = data.data.data.creditFeeAcountId;
        this.selectedInstallmentAccount = data.data.data.installMoneyAccountId;
        this.selectedCashAccountCode = data.data.data.cashAccountCode;
        this.selectedClassAccountantCode = data.data.data.transferAccountCode;
        this.selectedClassCreditAccCode = data.data.data.creditAccountCode;
        this.selectedClassCreditFeeAccCode = data.data.data.creditFeeAcountCode;
        this.selectedInstallmentAccountCode =
          data.data.data.installMoneyAccountCode;
        this.billDetailTitle = `Chi tiết hóa đơn: #${this.billNumber} - Ngày: ${data.data.data.createdAt}`;
        this.managerName = data.data.data.managerName;
        this.assistantName = data.data.data.assistantName;
        this.customerReceptionName = data.data.data.customerReceptionName;
        this.transferReferenceCode = data.data.data.transferReferenceCode;
        this.creditCardCode = data.data.data.creditCardNo;
        this.creditCardFee = data.data.data.otherFees;
        this.selectedCreditFeeAcc = data.data.data.creditFeeAccName;
        this.selectedPayCreditFeeType = data.data.data.payCreditFeeType;
        this.warrantyNote = data.data.data.warrantyNote;
        this.moneyInstallment = data.data.data.moneyInstallment;
        this.moneyCredit = data.data.data.moneyCredit;
        this.transferFromOrder = data.data.data.transferFromOrder;
        this.depositAmount = data.data.data.depositAmount;
        this.orderId = data.data.data.orderId;
        this.getListEmployeeByStore();
        this.shipperName = data.data.data.shipperName;
      });
    },
    sortByItemType(a, b) {
      if (a.billItemType < b.billItemType) {
        return -1;
      }
      if (a.billItemType > b.billItemType) {
        return 1;
      }
      return 0;
    },
    groupBy(arr, prop) {
      const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
      arr.forEach((obj) => map.get(obj[prop]).push(obj));
      return Array.from(map.values());
    },
    sortListBillItem(listItem) {
      let listResult = [];
      listItem.sort(this.sortByItemType);
      let listGroup = this.groupBy(listItem, 'billItemType');
      if (listGroup.length === 1) {
        listGroup[0].forEach((element) => {
          listResult.push(element);
        });
      } else if (listGroup.length === 2) {
        listGroup[0].forEach((element) => {
          listResult.push(element);
          listGroup[1].forEach((ele) => {
            if (element.id === ele.parentId) {
              listResult.push(ele);
            }
          });
        });
      } else if (listGroup.length === 3) {
        listGroup[0].forEach((element) => {
          listResult.push(element);
          listGroup[1].forEach((ele) => {
            if (element.id === ele.parentId) {
              listResult.push(ele);
            }
          });
          listGroup[2].forEach((item) => {
            if (element.id === item.parentId) {
              listResult.push(item);
            }
          });
        });
      }
      return listResult;
    },
    showModalAddProductBonus(id) {
      this.addProBonusForBillItemId = id;
      this.countProductBonus = 0;
      this.listProductBonus = [];
      for (var i = 0; i < this.listBillItem.length; i++) {
        if (this.listBillItem[i].id === this.addProBonusForBillItemId) {
          this.modalTitle =
            'Thêm quà tặng cho sản phẩm ' + this.listBillItem[i].name;
        }
      }
      this.$refs['add-product-bonus-modal'].show();
    },
    hideModalAddProductBonus() {
      this.$refs['add-product-bonus-modal'].hide();
    },
    hideModalChangeProductBonus() {
      this.$refs['change-product-bonus-modal'].hide();
    },
    onInputChangeProductBonus(text) {
      if (!text) {
        text = '';
      }

      this.selectedProductBonus = text;
      this.debounceInputProductBonus();
    },
    onSelectedProductBonus(option) {
      let productBonus = option.item;
      this.selectedProductBonus = '';
      if (productBonus.quantityInStock > 0 || productBonus.productType) {
        this.countProductBonus++;
        let accessoryBonus = {
          id: this.countProductBonus,
          productId: productBonus.productId,
          name: productBonus.productName,
          code: 'Quà tặng kèm',
          price: productBonus.sellingPrice,
          quantity: 1,
          type: productBonus.productType,
          imeiCode: '',
          totalPrice: productBonus.sellingPrice,
          discount: '',
          quantityInStock: productBonus.quantityInStock,
          storeId: this.selectedClassStore,
          billItemType: 2,
          checked: true,
          returnPrice: productBonus.returnSellingPrice,
        };
        this.listProductBonus.push(accessoryBonus);
      } else {
        this.makeToastFaile('Số lượng sản phẩm tồn trong kho không đủ!');
      }
    },
    deleteProductBonus(item) {
      let id = item.id;
      for (var i = 0; i < this.listProductBonus.length; i++) {
        if (this.listProductBonus[i].id === id) {
          this.listProductBonus.splice(i, 1);
        }
      }
      this.listProductBonus.forEach((element, index) => {
        this.listProductBonus[index].id = index + 1;
        this.countProductBonus = index + 1;
      });
    },
    addProductBonusToListBillItem() {
      this.listProductBonus.forEach((element) => {
        this.countProduct++;
        let accessoryBonus = {
          id: this.countProduct,
          productId: element.productId,
          name: element.name,
          code: 'Quà tặng kèm',
          price: element.price,
          quantity: 1,
          type: element.type,
          imeiCode: '',
          totalPrice: element.price,
          discount: '',
          quantityInStock: element.quantityInStock,
          storeId: this.selectedClassStore,
          billItemType: 2,
          checked: true,
          parentId: this.addProBonusForBillItemId,
          returnPrice: element.returnPrice,
          accessoryGroupId: '',
          accessoryGroupCode: '',
        };
        this.listBillItem.push(accessoryBonus);
      });
      //this.listBillItem.sort(this.sortById);
      this.listBillItem = this.sortListBillItem(this.listBillItem);
      this.hideModalAddProductBonus();
    },
    addProductPromotionToListBillItem() {
      this.listProductPromotion.forEach((element) => {
        if (element.checked) {
          this.countProduct++;
          let accessoryPromotion = {
            id: this.countProduct,
            productId: element.productId,
            name: element.name,
            code: element.code,
            price: element.price,
            quantity: 1,
            type: element.type,
            imeiCode: '',
            totalPrice: element.price,
            discount: '',
            quantityInStock: element.quantityInStock,
            storeId: this.selectedClassStore,
            billItemType: 3,
            checked: true,
            parentId: this.addProPromotionForBillItemId,
            returnPrice: element.returnPrice,
            accessoryGroupId: '',
            accessoryGroupCode: '',
          };
          this.listBillItem.push(accessoryPromotion);
        }
      });
      this.listBillItem = this.sortListBillItem(this.listBillItem);
      this.hideModalAddProductPromotion();
    },
    sortById(a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    },
    showModalChangeProductBonus(id) {
      this.currentBonusProductId = id;
      let product = null;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.id === id) {
          product = element;
          break;
        }
      }
      this.choosedPro = product.productId;
      this.listProductVariant = product.listProductVariant;
      this.$refs['change-product-bonus-modal'].show();
    },
    onChangeProductBonus() {
      let choosedProduct = null;
      for (let index = 0; index < this.listProductVariant.length; index++) {
        const element = this.listProductVariant[index];
        if (element.productId === this.choosedPro) {
          choosedProduct = element;
          break;
        }
      }
      for (let index = 0; index < this.listBillItem.length; index++) {
        if (this.listBillItem[index].id === this.currentBonusProductId) {
          this.listBillItem[index].productId = choosedProduct.productId;
          this.listBillItem[index].name = choosedProduct.productName;
          break;
        }
      }
      this.$refs['change-product-bonus-modal'].hide();
    },
    showModalAddProductPromotion(id) {
      this.addProPromotionForBillItemId = id;
      this.countProductPromotion = 0;
      this.listProductPromotion = [];
      this.checkAllPro = false;
      for (var i = 0; i < this.listBillItem.length; i++) {
        if (this.listBillItem[i].id === this.addProPromotionForBillItemId) {
          this.modalTitle =
            'Thêm SP bán kèm cho sản phẩm ' + this.listBillItem[i].name;
          let listProductAttach = this.listBillItem[i].listAccessoryPromotion;
          listProductAttach.forEach((element) => {
            let code = `SP bán kèm- ${element.accessoryGroupCode}`;
            this.countProduct++;
            let accessoryPromotion = {
              id: this.countProduct,
              productId: element.productId,
              name: element.productName,
              code: code,
              price: element.sellingPrice,
              quantity: 1,
              type: element.productType,
              imeiCode: '',
              totalPrice: element.sellingPrice,
              discount: '',
              quantityInStock: element.quantityInStock,
              storeId: this.selectedClassStore,
              billItemType: 3,
              checked: false,
              parentId: this.addProPromotionForBillItemId,
              returnPrice: element.price,
              accessoryGroupId: element.accessoryGroupId,
              accessoryGroupCode: element.accessoryGroupCode,
            };
            this.listProductPromotion.push(accessoryPromotion);
          });
        }
      }
      this.$refs['add-product-promotion-modal'].show();
    },
    hideModalAddProductPromotion() {
      this.$refs['add-product-promotion-modal'].hide();
    },
    clickAll() {
      this.listProductPromotion.forEach((element, index) => {
        this.listProductPromotion[index].checked = !this.checkAllPro;
      });
    },
    clickIndex(id) {
      let check = true;
      for (let index = 0; index < this.listProductPromotion.length; index++) {
        if (this.listProductPromotion[index].id === id) {
          let checked = this.listProductPromotion[index].checked;
          this.listProductPromotion[index].checked = !checked;
        }
      }
      for (let index = 0; index < this.listProductPromotion.length; index++) {
        const element = this.listProductPromotion[index];
        if (element.checked === false) {
          check = false;
          break;
        }
      }
      this.checkAllPro = check;
    },
    showModalAddNote(id) {
      this.productIdUpdateNote = id;
      for (var i = 0; i < this.listBillItem.length; i++) {
        if (this.listBillItem[i].id === id) {
          this.productNote = this.listBillItem[i].name;
          this.noteBillItem = this.listBillItem[i].note;
        }
      }
      this.$refs['add-note-modal'].show();
    },
    hidenModalAddNote() {
      this.$refs['add-note-modal'].hide();
    },
    updateNote() {
      for (var i = 0; i < this.listBillItem.length; i++) {
        if (this.listBillItem[i].id === this.productIdUpdateNote) {
          this.listBillItem[i].note = this.noteBillItem;
        }
      }
      this.$refs['add-note-modal'].hide();
    },
    formatBillItem(listItem) {
      let listResult = [];
      listItem.sort(this.sortByItemType);
      let listGroup = this.groupBy(listItem, 'billItemType');
      if (listGroup.length === 1) {
        listGroup[0].forEach((element) => {
          let item = {
            productId: element.productId,
            productPrice: element.price ? unMaskPrice(element.price) : 0,
            quantity: element.quantity ? unMaskPrice(element.quantity) : 1,
            discountAmount: element.discount
              ? unMaskPrice(element.discount)
              : 0,
            imeiCode: element.imeiCode,
            productType: element.billItemType,
            accessoryGroupId: element.accessoryGroupId,
            accessoryGroupCode: element.accessoryGroupCode,
            repurchasePrice: element.returnPrice
              ? unMaskPrice(element.returnPrice)
              : 0,
            isGiftTaken: element.checked,
            note: element.note,
            gifts: [],
            attachs: [],
            discountType: element.discountType,
            discountProgramId: element.discountProgramId,
          };
          listResult.push(item);
        });
      } else if (listGroup.length === 2) {
        listGroup[0].forEach((element) => {
          let listGift = [];
          let listAttach = [];
          listGroup[1].forEach((ele) => {
            if (element.id === ele.parentId) {
              let item = {
                productId: ele.productId,
                productPrice: ele.price ? unMaskPrice(ele.price) : 0,
                quantity: ele.quantity ? unMaskPrice(ele.quantity) : 1,
                discountAmount: ele.discount ? unMaskPrice(ele.discount) : 0,
                imeiCode: ele.imeiCode,
                productType: ele.billItemType,
                accessoryGroupId: ele.accessoryGroupId,
                accessoryGroupCode: ele.accessoryGroupCode,
                repurchasePrice: ele.returnPrice
                  ? unMaskPrice(ele.returnPrice)
                  : 0,
                isGiftTaken: ele.checked,
                note: '',
              };
              if (ele.billItemType === 2) {
                listGift.push(item);
              } else if (ele.billItemType === 3) {
                listAttach.push(item);
              }
            }
          });
          let billItem = {
            productId: element.productId,
            productPrice: element.price ? unMaskPrice(element.price) : 0,
            quantity: element.quantity ? unMaskPrice(element.quantity) : 1,
            discountAmount: element.discount
              ? unMaskPrice(element.discount)
              : 0,
            imeiCode: element.imeiCode,
            productType: element.billItemType,
            accessoryGroupId: element.accessoryGroupId,
            accessoryGroupCode: element.accessoryGroupCode,
            repurchasePrice: element.returnPrice
              ? unMaskPrice(element.returnPrice)
              : 0,
            isGiftTaken: element.checked,
            note: element.note,
            gifts: listGift,
            attachs: listAttach,
            discountType: element.discountType,
            discountProgramId: element.discountProgramId,
          };
          listResult.push(billItem);
        });
      } else if (listGroup.length === 3) {
        listGroup[0].forEach((element) => {
          let listGift = [];
          let listAttach = [];
          listGroup[1].forEach((ele) => {
            if (element.id === ele.parentId) {
              let item = {
                productId: ele.productId,
                productPrice: ele.price ? unMaskPrice(ele.price) : 0,
                quantity: ele.quantity ? unMaskPrice(ele.quantity) : 1,
                discountAmount: ele.discount ? unMaskPrice(ele.discount) : 0,
                imeiCode: ele.imeiCode,
                productType: ele.billItemType,
                accessoryGroupId: ele.accessoryGroupId,
                accessoryGroupCode: ele.accessoryGroupCode,
                repurchasePrice: ele.returnPrice
                  ? unMaskPrice(ele.returnPrice)
                  : 0,
                isGiftTaken: ele.checked,
                note: '',
              };
              listGift.push(item);
            }
          });
          listGroup[2].forEach((ele) => {
            if (element.id === ele.parentId) {
              let item = {
                productId: ele.productId,
                productPrice: ele.price ? unMaskPrice(ele.price) : 0,
                quantity: ele.quantity ? unMaskPrice(ele.quantity) : 1,
                discountAmount: ele.discount ? unMaskPrice(ele.discount) : 0,
                imeiCode: ele.imeiCode,
                productType: ele.billItemType,
                accessoryGroupId: ele.accessoryGroupId,
                accessoryGroupCode: ele.accessoryGroupCode,
                repurchasePrice: ele.returnPrice
                  ? unMaskPrice(ele.returnPrice)
                  : 0,
                isGiftTaken: ele.checked,
                note: '',
              };
              listAttach.push(item);
            }
          });
          let billItem = {
            productId: element.productId,
            productPrice: element.price ? unMaskPrice(element.price) : 0,
            quantity: element.quantity ? unMaskPrice(element.quantity) : 1,
            discountAmount: element.discount
              ? unMaskPrice(element.discount)
              : 0,
            imeiCode: element.imeiCode,
            productType: element.billItemType,
            accessoryGroupId: element.accessoryGroupId,
            accessoryGroupCode: element.accessoryGroupCode,
            repurchasePrice: element.returnPrice
              ? unMaskPrice(element.returnPrice)
              : 0,
            isGiftTaken: element.checked,
            note: element.note,
            gifts: listGift,
            attachs: listAttach,
            discountType: element.discountType,
            discountProgramId: element.discountProgramId,
          };
          listResult.push(billItem);
        });
      }
      return listResult;
    },
    linkToListImei(id) {
      let item = null;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.id === id) {
          item = element;
          break;
        }
      }
      if (item) {
        const { href } = this.$router.resolve({
          name: 'list-imeis',
          query: {
            searchProduct: item.name,
            storeId: this.selectedClassStore,
          },
        });
        window.open(href, '_blank');
      }
    },
    viewInventory(id) {
      let item = null;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.id === id) {
          item = element;
          break;
        }
      }
      if (item) {
        let nameRouter = '';
        if (item.type === 2) {
          nameRouter = 'inventory-detail-imei';
        } else {
          nameRouter = 'inventory-detail';
        }
        const { href } = this.$router.resolve({
          name: nameRouter,
          query: {
            id: item.productId,
          },
        });
        window.open(href, '_blank');
      }
    },
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, 2000),
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    debounceInputProduct: decounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    debounceInputProductBonus: decounce(function () {
      let textSearch = this.selectedProductBonus;
      this.fetchProduct(textSearch, 2);
    }, TIME_TRIGGER),
    searchProductAPI() {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        if (this.selectTypeSearch === 1) {
          this.fetchProduct(textSearch, 1);
        } else if (this.selectTypeSearch === 2) {
          this.fetchProductImei(textSearch);
        }
      }
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          if (data.data.jobTitleId === 6) {
            this.cashierName = data.data.employeeName;
          }
        }
        this.fetchCashAcc();
      });
    },
    onSelectedManager(option) {
      this.managerName = option.item;
    },
    onSelectedAssistant(option) {
      this.assistantName = option.item;
    },
    onSelectedCustomerReception(option) {
      this.customerReceptionName = option.item;
    },
    onInputChangeManager(text) {
      if (!text) {
        text = '';
      }
      this.managerName = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item);
          return (
            item.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onInputChangeAssistant(text) {
      if (!text) {
        text = '';
      }
      this.assistantName = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item);
          return (
            item.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onInputChangeCustomerReception(text) {
      if (!text) {
        text = '';
      }
      this.customerReceptionName = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item);
          return (
            item.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    getCreditFee: async function () {
      await ApiService.setHeader();
      await ApiService.query('settings/credit-fee').then((res) => {
        this.creditFeeRate = res.data.data;
      });
    },
    onCreditAmountChange() {
      this.creditCardFee =
        (unMaskPrice(this.creditAmount) * this.creditFeeRate) / 100;
    },
    onCreditFeePayTypeChange() {
      if (this.selectedPayCreditFeeType === 1) {
        this.listCreditFeeAccSearch = this.listCashAccSearch;
        this.creditFeeAccOptions[0].data = this.cashAccOptions[0].data;
        let tmp = [{ data: this.creditFeeAccOptions[0].data }];
        this.filteredCreditFeeAccOptions = tmp;
      } else {
        this.listCreditFeeAccSearch = this.listAccSearch;
        this.creditFeeAccOptions[0].data = this.accountantOptions[0].data;
        let tmp = [{ data: this.creditFeeAccOptions[0].data }];
        this.filteredCreditFeeAccOptions = tmp;
      }
    },
    onCreditFeeAccSelected(option) {
      this.selectedCreditFeeAcc = option.item.name;
      this.selectedClassCreditFeeAcc = option.item.id;
      this.selectedClassCreditFeeAccCode = option.item.code;
    },
    onInputCreditFeeAccChange(text) {
      this.selectedCreditFeeAcc = text;
      const filteredData = this.creditFeeAccOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCreditFeeAccOptions = [
        {
          data: filteredData,
        },
      ];
    },
    bindingMoney(type) {
      if (type === 1) {
        this.cashAmount =
          this.totalPrice -
          this.transferAmount -
          unMaskPrice(this.creditAmount) -
          unMaskPrice(this.installedMoneyAmount);
      } else if (type === 2) {
        this.transferAmount =
          this.totalPrice -
          unMaskPrice(this.cashAmount) -
          unMaskPrice(this.creditAmount) -
          unMaskPrice(this.installedMoneyAmount);
      } else if (type === 3) {
        this.creditAmount =
          this.totalPrice -
          unMaskPrice(this.cashAmount) -
          unMaskPrice(this.transferAmount) -
          unMaskPrice(this.installedMoneyAmount);
      } else if (type === 4) {
        this.installedMoneyAmount =
          this.totalPrice -
          unMaskPrice(this.cashAmount) -
          unMaskPrice(this.transferAmount) -
          unMaskPrice(this.creditAmount);
      }
    },
    getListEmployeeByStore() {
      this.optionsEmployee[0].data = [];
      this.filteredOptionsEmployee = [{ data: this.optionsEmployee[0].data }];
      // if (this.searchStock.trim() === '') {
      //   this.selectedStore = null;
      // }
      let listStoreId = [];
      listStoreId.push(this.selectedClassStore);
      ApiService.setHeader();
      ApiService.get(
        `employees/get-by-store?employeeName=&storeId=${listStoreId}`
      ).then((data) => {
        const employees = data.data.data;
        employees.map((element) => {
          this.optionsEmployee[0].data.push(element.fullName);
        });
        this.filteredOptionsEmployee = [{ data: this.optionsEmployee[0].data }];
        // auto fill for HTKT
        let listHTKT = employees.filter((item) => item.jobTitleId === 8);
        if (listHTKT && listHTKT.length === 1) {
          this.technicalName = listHTKT[0].fullName;
        }
        // auto fill for QL
        let listQL = employees.filter((item) => item.jobTitleId === 4);
        if (listQL && listQL.length === 1 && this.managerName.trim() === '') {
          this.managerName = listQL[0].fullName;
        }
        // auto fill for TL
        let listTL = employees.filter((item) => item.jobTitleId === 5);
        if (listTL && listTL.length === 1 && this.assistantName.trim() === '') {
          this.assistantName = listTL[0].fullName;
        }
        // auto fill for TDKH
        let listTDKH = employees.filter((item) => item.jobTitleId === 9);
        if (
          listTDKH &&
          listTDKH.length === 1 &&
          this.customerReceptionName.trim() === ''
        ) {
          this.customerReceptionName = listTDKH[0].fullName;
        }
      });
    },
    async fetchProductWarranty(productId) {
      this.onLoading = true;
      ApiService.setHeader();
      ApiService.get(`productSearch/warranty-product/${productId}`).then(
        ({ data }) => {
          const listData = data.data;
          listData.forEach((element) => {
            const item = {
              ...element,
              checked: false,
            };
            this.listProductWarranty.push(item);
          });

          this.onLoading = false;
        }
      );
    },
    showModalAddProductWarranty(productId) {
      this.modalTitle = 'Thêm sản phẩm bảo hành';
      this.listProductWarranty = [];
      this.checkAllProWarranty = false;
      this.fetchProductWarranty(productId);
      this.$refs['add-product-warranty-modal'].show();
    },
    hideModalAddProductWarranty() {
      this.$refs['add-product-warranty-modal'].hide();
    },
    addProductWarrantyToListBillItem() {
      this.listProductWarranty.forEach((element) => {
        if (element.checked) {
          this.countProduct++;
          this.proSTT++;
          const item = {
            id: this.countProduct,
            stt: this.proSTT,
            productId: element.id,
            name: element.productName,
            code: element.productCode,
            price: element.sellingPrice,
            quantity: 1,
            type: 4,
            imeiCode: '',
            totalPrice: element.sellingPrice,
            discount: 0,
            quantityInStock: 0,
            storeId: this.selectedClassStore,
            billItemType: 1,
            checked: true,
            parentId: null,
            returnPrice: 0,
            accessoryGroupId: '',
            accessoryGroupCode: '',
            isGetVat: false,
            note: '',
            discountType: AMOUNT_TYPE.MONEY,
            discountProgramId: -1,
          };
          this.listBillItem.push(item);
        }
      });
      this.listBillItem = this.sortListBillItem(this.listBillItem);
      this.hideModalAddProductWarranty();
    },
    clickAllWarranty() {
      this.listProductWarranty.forEach((element, index) => {
        this.listProductWarranty[index].checked = !this.checkAllProWarranty;
      });
    },
    clickIndexWarranty() {
      let check = true;
      for (let index = 0; index < this.listProductWarranty.length; index++) {
        const element = this.listProductWarranty[index];
        if (element.checked === false) {
          check = false;
          break;
        }
      }
      this.checkAllProWarranty = check;
    },
    linkToWarranty(id) {
      this.$router.push({
        name: 'add-import-warranty',
        query: { refId: id },
      });
    },
    onSelectedShipper(option) {
      this.shipperName = option.item;
    },
    onInputChangeShipper(text) {
      if (!text) {
        text = '';
      }
      this.shipperName = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          const nameNoneSign = xoa_dau(item);
          return (
            item.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
  },
};
</script>

<style>
.headerTable {
  color: rgb(24, 28, 50);
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}

.form-group label {
  font-weight: 500;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.textPayment {
  font-size: 16px;
  color: brown;
  font-weight: 500;
}

.textTitleFinal {
  font-weight: 500;
}

.textFinal {
  font-weight: 500;
  color: brown;
}
.textTitlePayment {
  font-weight: 500;
  font-size: 14px;
}

.textPayment {
  font-weight: 500;
  color: brown;
}

.textExcessCash {
  font-weight: 500;
  color: green;
}

.row-center-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
}
.textPoint {
  font-size: 14px;
  font-weight: 500;
}
.alert-header {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
</style>
